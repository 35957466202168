import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { statesList } from '../../../utils/statesList'
import { Button, Flex, Text } from '../../../ui-kit'
import { getPaginationData, parseFilterQuery } from '../../../utils/utils'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import sizes from '../../../ui-kit/sizes'
import fontWeight from '../../../ui-kit/fontWeight'
import Sidebar from '../../../ui-kit/components/sidebar/Sidebar'
import SidebarLienContent from './SidebarLienContent'
import { finderAvailableFilters as availableFilters } from './mocks'
import { DateTime } from 'luxon'
import { useQueryParams } from '../../../hooks/useQueryParams'
import SearchFilters from '../searchFilters/SearchFilters'
import { applyFilters } from '../../reports/reportsFilters/logic/applyFilters'
import { getDateRange } from '../../../ui-kit/components/datePicker/logic'
import { useLazyQuery } from '@apollo/react-hooks'
import { LienMonitorPartySearch } from '../../../queriesUpdated/queries/lienMonitorPartySearch.gql'
import { toInteger } from 'lodash'
import { getCustomRowId } from '../../reports/logic/reportsUtils'

const SearchByParty = () => {
  const { t } = useTranslation()
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [selectedLien, setSelectedLien] = useState({})
  const { queryParams, setQueryParams } = useQueryParams()
  const page = toInteger(queryParams.page) || 1
  const filtersQuery = queryParams.filters || null
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery, false), [filtersQuery])

  const pageSize = 100
  const [loadLiens, { data, loading: liensLoading, called }] = useLazyQuery(
    LienMonitorPartySearch,
    {
      variables: {
        page,
        perPage: pageSize,
        partyName: userFilters?.partyName,
        partyAddress: userFilters?.address,
        partyCity: userFilters?.city,
        partyState: userFilters?.state,
        exactMatch: userFilters?.exactMatch === 'true',
        lienAgeRangeFrom: userFilters?.date?.[0],
        lienAgeRangeTo: userFilters?.date?.[1],
      },
      skip: true,
    },
  )

  useEffect(() => {
    userFilters && loadLiens()
  }, [userFilters])

  const [selectedFilter, setSelectedFilters] = useState(
    filtersQuery?.length
      ? {}
      : {
          date: getDateRange('minus', { months: 12 }),
        },
  )
  const filterColumns = useMemo(
    () => [
      {
        filterId: 'partyName',
        filterTitle: t('partyName'),
        filterWrapperClassName: '!w-full col-span-5 pr-8',
        required: true,
        hint: t('lienPartyNameHint'),
      },
      {
        filterId: 'exactMatch',
        filterTitle: t('exactMatch'),
        filterWrapperClassName: '!w-full col-span-1',
        disabled: !Object.keys(selectedFilter).find((filterKey) => filterKey === 'partyName'),
        hint: t('lienExactMatchHint'),
      },
      {
        filterId: 'date',
        filterTitle: t('filingDate'),
        filterWrapperClassName: '!w-full col-span-3 pr-8 pl-4',
        maxDate: DateTime.now().toJSDate(),
        required: false,
        excludedActions: ['nextWeek'],
        hint: t('lienFilingDateHint'),
      },
      {
        filterId: 'address',
        filterTitle: t('address'),
        filterWrapperClassName: '!w-full col-span-5 col-start-1 col-end-6 pr-8',
        required: false,
        hint: t('lienPartyAddressHint'),
      },
      {
        filterId: 'city',
        filterTitle: t('cityRecommend'),
        filterWrapperClassName: '!w-full col-span-4 pr-8',
        required: false,
        hint: t('lienPartyCityHint'),
      },
      {
        filterId: 'state',
        filterTitle: t('state'),
        filterOptions: statesList.map((state) => ({
          key: state.value,
          title: state.label,
        })),
        filterWrapperClassName: '!w-full col-span-3 sm:col-span-5 xl:col-span-3 sm:pr-8 xl:pr-0',
        ignoreMultipleSelect: true,
        required: true,
        hint: t('lienPartyStateHint'),
      },
    ],
    [selectedFilter],
  )
  const rows = data?.lienMonitorPartySearch?.data || []
  const paginationData = getPaginationData(data?.lienMonitorPartySearch)
  const columns = useMemo(
    () => [
      {
        field: 'recordedDate',
        headerName: t('filingDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.recordedDate} />,
        width: 120,
        sortable: false,
      },
      {
        field: 'projectDetails',
        headerName: t('projectDetails'),
        renderCell: (values) => (
          <Flex className="w-full" column>
            <Text className="text-ellipsis overflow-hidden">{values?.row?.projectName}</Text>
            <Text className="text-ellipsis overflow-hidden">{values?.row?.projectAddress}</Text>
          </Flex>
        ),
        sortable: false,
        flex: 3,
      },
      {
        field: 'partyDetails',
        headerName: t('partyDetails'),
        renderCell: (values) => (
          <Flex column>
            {values?.row?.parties?.map((party) => (
              <Text className="w-full whitespace-pre-wrap" key={party.partyName}>
                {party.partyName} ({party.partyType})
              </Text>
            )) || []}
          </Flex>
        ),
        sortable: false,
        flex: 3,
      },
      {
        field: 'debtorClaimant',
        headerName: t('debtorClaimant'),
        renderCell: (values) => (
          <Flex className="w-full" column>
            <Text className="text-ellipsis overflow-hidden">{values?.row?.debtorName}</Text>
            <Text className="text-ellipsis overflow-hidden">{values?.row?.claimantName}</Text>
          </Flex>
        ),
        sortable: false,
        flex: 2,
      },
    ],
    [],
  )
  const getRowId = useCallback((row) => getCustomRowId(row, 'lienfinderId'), [])

  const handleRowClick = (model) => {
    setSelectedLien(model?.row)
    setIsSidebarOpened(true)
  }

  const isRunSearchDisabled = useMemo(() => {
    const requiredFilters = filterColumns
      .filter((filter) => filter.required)
      .map((filter) => filter.filterId)

    if (!requiredFilters.length) {
      return true
    }

    return !requiredFilters.every((requiredFilter) =>
      Object.keys(selectedFilter).find((filterKey) => filterKey === requiredFilter),
    )
  }, [selectedFilter])
  const handleRunSearchClick = () => {
    applyFilters({ filters: selectedFilter, setQueryParams })
  }

  return (
    <>
      <SearchFilters
        availableFilters={availableFilters}
        columns={filterColumns}
        contentWrapperClassName="gap-x-0 grid grid-cols-12 sm:grid-cols-10 xl:grid-cols-12"
        selectedFilter={selectedFilter}
        setSelectedFilters={setSelectedFilters}
      />
      <Flex className="pt-8 pb-6" justifyContent="center">
        <Button
          disabled={liensLoading || isRunSearchDisabled}
          label={t('runSearch')}
          onClick={handleRunSearchClick}
        />
      </Flex>
      {called && (
        <Flex column>
          <Text className="pb-10" fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
            {t('searchResults', { count: paginationData?.totalCount || '-' })}
          </Text>
          <DataGridComponent
            columns={columns}
            getRowHeight={() => 'auto'}
            getRowId={getRowId}
            loading={liensLoading}
            page={page}
            pageSize={pageSize}
            paginationData={paginationData}
            rowClassName="cursor-pointer"
            rowClick={handleRowClick}
            rows={rows}
          />
        </Flex>
      )}
      <Sidebar
        clearSelectedInvoice={() => setSelectedLien({})}
        isSidebarOpened={isSidebarOpened}
        setIsSidebarOpened={setIsSidebarOpened}>
        <SidebarLienContent data={selectedLien} />
      </Sidebar>
    </>
  )
}

export default SearchByParty
