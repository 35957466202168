import { Button, LoadingSpinner, Money, StatusBadge, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import React, { useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { LienMonitorProjectQuery } from '../../../queriesUpdated/queries/lienMonitorProject.gql'
import { LienMonitorDocumentQuery } from '../../../queriesUpdated/queries/lienMonitorDocument.gql'
import { useLazyQuery } from '@apollo/react-hooks'
import { handleError } from '../../../utils/utils'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useNotifications } from '../../../hooks/useNotifications'
import { useSelector } from 'react-redux'
import { selectAuthToken } from '../../../store/selectors/auth'
import { useRollbar } from '@rollbar/react'
import { downloadFile } from '../../reports/logic/reportsUtils'

const SidebarLienContent = ({ data }) => {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const authToken = useSelector(selectAuthToken)
  const { newNotification } = useNotifications()
  const [isContentLoading, setIsContentLoading] = useState(false)

  const { data: projectData, loading } = useCustomQuery({
    query: LienMonitorProjectQuery,
    queryOptions: {
      skip: !data?.lienfinderId,
      variables: { lienFinderId: data?.lienfinderId },
    },
    rollbarOptions: {
      operationName: 'LienMonitorProjectQuery',
      target: 'SidebarLienContent',
    },
  })
  const lienProject = projectData?.lienMonitorProject || {}

  const [createLienDocumentUrl, { loading: loadingLienDocumentUrl }] = useLazyQuery(
    LienMonitorDocumentQuery,
    {
      skip: true,
      onCompleted: async (data, errors) => {
        if (data?.lienMonitorDocument?.url && authToken) {
          setIsContentLoading(true)
          await downloadFile(
            data.lienMonitorDocument.url,
            authToken,
            '',
            `${lienProject?.docDesc || 'document'}.pdf`,
            rollbar,
          )
          setIsContentLoading(false)
        } else {
          handleError(data?.lienMonitorDocument?.errors || errors, newNotification)
        }
      },
    },
  )
  useEffect(() => {}, [])
  const handleViewDocument = () => {
    createLienDocumentUrl({ variables: { documentId: lienProject?.docId } })
  }

  const lienInformationData = useMemo(
    () => [
      {
        label: t('claimAmount'),
        value: data.claimAmount ? <Money value={data.claimAmount * 100} /> : <Text>{t('nA')}</Text>,
      },
      {
        label: t('firstFurnishedDate'),
        value: <FormattedDate date={lienProject?.firstFurn} />,
        hidden: !lienProject?.firstFurn,
      },
      {
        label: t('lastFurnishedDate'),
        value: <FormattedDate date={lienProject?.lastFurn} />,
        hidden: !lienProject?.lastFurn,
      },
      {
        label: t('filingDate'),
        value: <FormattedDate date={data.recordedDate} />,
      },
      {
        label: t('lienFinderId'),
        value: data.lienfinderId,
      },
    ],
    [data, lienProject],
  )
  const projectInformationData = useMemo(
    () => [
      {
        label: t('projectName'),
        value: data.projectName?.trim() || '-',
      },
      {
        label: t('projectType'),
        value: data.projectType?.trim() || '-',
      },
      {
        label: t('address'),
        value: data.projectAddress?.trim() || '-',
      },
    ],
    [data],
  )
  const ladderOfSupplyData = useMemo(
    () =>
      lienProject?.parties?.reduce((acc, party) => {
        if (!party?.partyType && !party?.partyName) {
          return acc
        }

        acc.push({
          label: (
            <>
              {party.partyType}
              {party.debtor && (
                <StatusBadge color="gray" iconName="dot" label={t('debtor')} value="current" />
              )}
              {party.claimant && (
                <StatusBadge color="gray" iconName="dot" label={t('claimant')} value="current" />
              )}
            </>
          ),
          value: party.partyName,
        })
        return acc
      }, []) || [],
    [lienProject],
  )

  return (
    <div className="flex flex-col mt-[-1.5rem]">
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
                {t('details')}
              </Text>
            </div>

            {lienProject?.docId && (
              <Button
                className="max-w-full text-left underline"
                disabled={loadingLienDocumentUrl || isContentLoading}
                label={lienProject?.docDesc || t('viewDocument')}
                onClick={handleViewDocument}
                variant={buttonsVariants.LINK}
              />
            )}
          </div>

          <div className="mt-5">
            <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.BASE}>
              {t('lienInformation')}
            </Text>
          </div>
          <DividedList content={lienInformationData} />

          <div className="mt-8">
            <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.BASE}>
              {t('projectInformation')}
            </Text>
          </div>
          <DividedList content={projectInformationData} />

          {!!ladderOfSupplyData.length && (
            <>
              <div className="mt-8">
                <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.BASE}>
                  {t('ladderOfSupply')}
                </Text>
              </div>
              <DividedList
                content={ladderOfSupplyData}
                labelTextClassName="flex !flex-row items-center gap-4"
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

SidebarLienContent.propTypes = {
  data: PT.object,
}

SidebarLienContent.defaultProps = {
  data: {},
}

export default SidebarLienContent
