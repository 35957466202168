import React, { useState, useMemo, useCallback, useEffect } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { contractDataShape } from '../../../types/invoiceShapes'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { alertsAvailableFilters } from './mocks'
import { getModelsByIds, getPaginationData, parseFilterQuery } from '../../../utils/utils'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { camelCase, toInteger } from 'lodash'
import { statesList } from '../../../utils/statesList'
import { Box, Button, Dialog, Flex, LoadingSpinner, Money, Text } from '../../../ui-kit'
import FiltersControlButton from '../../../components/filters/FiltersControlButton'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import EditCustomerAlertsModal from './forms/EditCustomerAlertsModal'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import EditGeneralSettingsForm from './forms/EditGeneralSettingsForm'
import sizes from '../../../ui-kit/sizes'
import PropTypes from 'prop-types'
import { LienMonitorVendorSettingQuery } from '../../../queriesUpdated/queries/lienMonitorVendorSetting.gql'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import {
  LienMonitorContractSettingQuery,
  LienMonitorContractSettingsQuery,
} from '../../../queriesUpdated/queries/lienMonitorContractSettings.gql'
import { getDisplayStatusValue } from '../../contracts/utils'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
// eslint-disable-next-line max-len
import { UpdateLienMonitorContractSettingMutation } from '../../../queriesUpdated/mutations/updateLienMonitorContractSetting.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import LienSettingsSelected from './LienSettingsSelected'

const LienSettings = ({ contractData, isGeneralSettingsOpened, setIsGeneralSettingsOpened }) => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const { newNotification } = useNotifications()
  const [contractSettings, setContractSettings] = useState({})
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [currentSelectionModel, setCurrentSelectionModel] = useState([])
  const [previousSelectionModel, setPreviousSelectionModel] = useState([])
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false)
  const [isCustomerEditAlertOpened, setIsCustomerEditAlertOpened] = useState(false)
  const sort = queryParams.sort || ''
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  const filtersQuery = queryParams.filters || null
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])

  const { data: settingsData, loading: vendorSettingsLoading } = useCustomQuery({
    query: LienMonitorVendorSettingQuery,
    rollbarOptions: {
      operationName: 'LienMonitorVendorSettingQuery',
      target: 'LienSettings',
    },
  })
  const generalSettings = settingsData?.lienMonitorVendorSetting || {}

  const { data: contractSettingData, loading: contractSettingLoading } = useCustomQuery({
    query: LienMonitorContractSettingQuery,
    queryOptions: {
      skip: !contractData,
      variables: { contractId: contractData?.id },
    },
    rollbarOptions: { operationName: 'LienMonitorContractSettingQuery', target: 'LienSettings' },
  })
  const contractSetting = contractSettingData?.lienMonitorContractSetting || {}

  const pageSize = 100
  const onCompletedRecords = useCallback(
    (response) => {
      setContractSettings(response.lienMonitorContractSettings)
      if (isSelectAllSelected) {
        setCurrentSelectionModel(response.lienMonitorContractSettings?.data || [])
      } else {
        divideSelection(response.lienMonitorContractSettings?.data || [], selectedCustomers)
      }
    },
    [previousSelectionModel, selectedCustomers],
  )
  const { loading: contractSettingsLoading } = useCustomQuery({
    query: LienMonitorContractSettingsQuery,
    onCompleted: onCompletedRecords,
    queryOptions: {
      skip: !!contractData,
      variables: { perPage: pageSize, page, sort, filters: userFilters, search },
    },
    rollbarOptions: { operationName: 'LienMonitorContractSettingsQuery', target: 'LienSettings' },
  })
  const rows = contractSettings.data || []
  const paginationData = getPaginationData(contractSettings)

  const [updateLienMonitorContractSetting, { loading: updateContractSettingLoading }] =
    useCustomMutation({
      mutation: UpdateLienMonitorContractSettingMutation,
      rollbarOptions: {
        operationName: 'UpdateLienMonitorContractSettingMutation',
        target: 'LienSettings',
      },
      mutationOptions: {
        refetchQueries: [LienMonitorContractSettingsQuery, LienMonitorContractSettingQuery],
      },
    })

  const handleChangeLienAlert = (values) => {
    /*let contractIds

    if (isSelectAllSelected) {
      contractIds = void 0
    } else if (values.contractId) {
      contractIds = [values.contractId]
    } else {
      contractIds = (selectedCustomers.length ? selectedCustomers : [selectedCustomer]).map(
        (customer) => customer?.contract?.id,
      )
    }*/

    const variables = {
      contractId: values.contractId,
      filters: isSelectAllSelected ? userFilters : void 0,
      data:
        isSelectAllSelected || selectedCustomers.length > 1
          ? { enabled: values.enabled }
          : values.enabled
          ? {
              enabled: values.enabled,
              // city: values.city,
              // state: values.state,
              // address: values.address,
            }
          : { enabled: values.enabled },
    }
    updateLienMonitorContractSetting({
      variables,
    }).then(({ data }) => {
      const responseData = data?.updateLienMonitorContractSetting || {}

      if (responseData?.entity) {
        newNotification({ success: t('lienAlertPreferencesUpdatedSuccessfully') })
        setIsCustomerEditAlertOpened(false)
        setSelectedCustomer({})
        setSelectedCustomers([])
      }
    })
  }

  const handleEditCustomerLienAlertSettings = (customerRow) => {
    setIsCustomerEditAlertOpened(true)
    setSelectedCustomer(customerRow)
  }

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('id'),
        renderCell: (values) => values?.row?.contract?.referenceId,
        flex: 1,
      },
      {
        field: 'customerName',
        headerName: t('customerName'),
        renderCell: (values) => values?.row?.contract?.buyer?.name,
        flex: 2,
      },
      {
        field: 'isLienAlerts',
        headerName: t('lienAlerts'),
        renderCell: (values) => (
          <Toggle
            className="pl-4 pr-5"
            disabled={updateContractSettingLoading || !!selectedCustomers.length}
            handleChange={() =>
              handleChangeLienAlert({
                enabled: !values?.row?.enabled,
                contractId: values?.row?.contract?.id,
              })
            }
            testData="toggle-customer-alerts-enabled"
            value={values?.row?.enabled}
          />
        ),
        flex: 1.5,
        sortable: false,
      },
      {
        field: 'state',
        headerName: t('state'),
        flex: 0.5,
        filterId: 'filingState',
        filterTitle: t('state'),
        filterOptions: statesList.map((state) => ({
          key: state.value,
          title: state.label,
        })),
      },
    ],
    [updateContractSettingLoading, selectedCustomers.length],
  )
  const { captions, values } = useMemo(() => {
    if (!contractData) {
      return { captions: [], values: [] }
    }

    return [
      {
        label: t('lienAlerts'),
        node: contractSettingLoading ? (
          <LoadingSpinner className="py-2.5" />
        ) : (
          <Text
            className="uppercase py-5"
            color={contractSetting.enabled ? 'text-green-600' : 'text-primary-700'}>
            {contractSetting.enabled ? t('enabled') : t('disabled')}
          </Text>
        ),
      },
      {
        label: t('minimumOSBalance'),
        node: vendorSettingsLoading ? (
          <LoadingSpinner className="py-2.5" />
        ) : (
          <Text
            className="uppercase py-5"
            color={!generalSettings.minimumOutstandingBalanceThresholdCents && 'text-primary-700'}>
            {generalSettings.minimumOutstandingBalanceThresholdCents ? (
              <Money
                className="py-5"
                value={generalSettings.minimumOutstandingBalanceThresholdCents}
              />
            ) : (
              t('disabled')
            )}
          </Text>
        ),
      },
      {
        label: t('customerStatusThreshold'),
        node: vendorSettingsLoading ? (
          <LoadingSpinner className="py-2.5" />
        ) : (
          <Text className="py-5">
            {generalSettings.vendorSettingOverdueLevels
              ?.reduce(
                (acc, overdueLevelGroup) => [
                  ...acc,
                  t(getDisplayStatusValue(overdueLevelGroup.overdueLevel)),
                ],
                [],
              )
              ?.join(', ') || '-'}
          </Text>
        ),
      },
      {
        label: t('customerName'),
        node: vendorSettingsLoading ? (
          <LoadingSpinner className="py-2.5" />
        ) : (
          <Flex className="py-5" gap={'5'}>
            <Text>{contractData?.buyer?.name || '-'}</Text>
            {generalSettings.exactMatch && (
              <Text className="uppercase" color="text-primary-700">
                {t('exactMatch')}
              </Text>
            )}
          </Flex>
        ),
      },
      {
        label: t('address'),
        node: contractSettingLoading ? (
          <LoadingSpinner className="py-2.5" />
        ) : (
          <Text className="py-5">{contractSetting.address || '-'}</Text>
        ),
      },
      {
        label: t('city'),
        node: contractSettingLoading ? (
          <LoadingSpinner className="py-2.5" />
        ) : (
          <Text className="py-5">{contractSetting.city || '-'}</Text>
        ),
      },
      {
        label: t('state'),
        node: contractSettingLoading ? (
          <LoadingSpinner className="py-2.5" />
        ) : (
          <Text className="py-5">{contractSetting.state || '-'}</Text>
        ),
      },
      {
        label: t('excludeIfClaimant'),
        node: vendorSettingsLoading ? (
          <LoadingSpinner className="py-2.5" />
        ) : (
          <Text
            className="uppercase py-5"
            color={generalSettings.excludeClaimant ? 'text-green-600' : 'text-primary-700'}>
            {generalSettings.excludeClaimant ? t('enabled') : t('disabled')}
          </Text>
        ),
      },
    ].reduce(
      (acc, setting) => {
        acc.captions.push(setting.label)
        acc.values.push(setting.node)
        return acc
      },
      { captions: [], values: [] },
    )
  }, [contractData, contractSettingLoading, vendorSettingsLoading])

  const handleEditAlertsClick = () => {
    setIsCustomerEditAlertOpened(true)
  }
  const divideSelection = (rows, model) => {
    const previousSelection = []
    const currentSelection = []
    const selectionModel = model || previousSelectionModel
    selectionModel.forEach((item) => {
      const index = rows.findIndex((row) => item.id === row.id)
      if (index > -1) {
        currentSelection.push(item)
      } else {
        previousSelection.push(item)
      }
    })
    setPreviousSelectionModel(previousSelection)
    setCurrentSelectionModel(currentSelection)
    setSelectedCustomers(previousSelection.concat(currentSelection))
    setSelectedCustomer({})
  }
  const setSelectionModel = (ids) => {
    const selected = getModelsByIds(rows, ids)
    setCurrentSelectionModel(selected)
    if (isSelectAllSelected) {
      setIsSelectAllSelected(false)
      setSelectedCustomers(selected)
    } else {
      setSelectedCustomers(previousSelectionModel.concat(selected))
    }
  }
  const handleSelectionModelChange = useCallback(
    (models) => {
      setSelectionModel(models)
    },
    [rows, previousSelectionModel],
  )
  const handleClearAll = () => {
    setSelectedCustomers([])
    setPreviousSelectionModel([])
    setCurrentSelectionModel([])
    setIsSelectAllSelected(false)
  }
  const handleSelectAll = () => {
    setPreviousSelectionModel([])
    setCurrentSelectionModel(rows)
    setIsSelectAllSelected(true)
  }
  useEffect(() => {
    setPreviousSelectionModel(selectedCustomers)
  }, [sort, page, search, filtersQuery])

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  return (
    <>
      {!contractData ? (
        <>
          <Flex className="pt-4 gap-x-2" justifyContent="between">
            <Flex
              alignItems="center"
              className="gap-x-4 overflow-hidden overflow-x-auto scrollbar-width-thin">
              <Text nowrap>
                {t('minOSBalance')}:{' '}
                <Text className="uppercase" color="text-primary-700">
                  {generalSettings.minimumOutstandingBalanceThresholdCents ? (
                    <Money value={generalSettings.minimumOutstandingBalanceThresholdCents} />
                  ) : (
                    t('disabled')
                  )}
                </Text>
              </Text>
              <Text nowrap>
                {t('statusThreshold')}:{' '}
                <Text color="text-primary-700">
                  {(!vendorSettingsLoading &&
                    generalSettings.vendorSettingOverdueLevels
                      ?.reduce(
                        (acc, overdueLevelGroup) => [
                          ...acc,
                          t(getDisplayStatusValue(overdueLevelGroup.overdueLevel)),
                        ],
                        [],
                      )
                      ?.join(', ')) ||
                    '-'}
                </Text>
              </Text>
              <Text nowrap>
                {t('exactMatch')}:{' '}
                <Text className="uppercase" color="text-primary-700">
                  {generalSettings.exactMatch ? t('enabled') : t('disabled')}
                </Text>
              </Text>
              <Text nowrap>
                {t('excludeIfClaimant')}:{' '}
                <Text className="uppercase" color="text-primary-700">
                  {generalSettings.excludeClaimant ? t('enabled') : t('disabled')}
                </Text>
              </Text>
            </Flex>
            <FiltersControlButton />
          </Flex>
          <div className={cx('flex pt-4', !!selectedCustomers.length && 'pb-12')}>
            <DataGridComponent
              availableFilters={alertsAvailableFilters}
              columns={columns}
              handleSelectionModelChange={handleSelectionModelChange}
              loading={contractSettingsLoading || vendorSettingsLoading}
              page={page}
              pageSize={pageSize}
              paginationData={paginationData}
              rowClassName="cursor-pointer"
              rows={rows}
              searchLabel={t('lienAlerts')}
              selectionModel={currentSelectionModel.map((customer) => customer.id)}
              sortModel={defaultSortModel}
              // checkboxSelection
            />
          </div>
          <LienSettingsSelected
            handleClearAll={handleClearAll}
            handleEditAlertsClick={handleEditAlertsClick}
            handleSelectAll={handleSelectAll}
            isSelectAllSelected={isSelectAllSelected}
            models={selectedCustomers}
            selectedContractLength={
              isSelectAllSelected ? paginationData.totalCount : selectedCustomers.length
            }
            showPanel={!!selectedCustomers.length}
            totalCountFilteredData={paginationData.totalCount}
          />
        </>
      ) : (
        <Flex column>
          <Flex className="w-3/5" justifyContent="end">
            <Button
              className="w-fit"
              disabled={
                !Object.keys(contractSetting).length ||
                contractSettingLoading ||
                vendorSettingsLoading
              }
              iconName="pencil"
              label={t('edit')}
              onClick={() => handleEditCustomerLienAlertSettings(contractSetting)}
              size={sizes.SM}
              variant={buttonsVariants.FOURTH}
            />
          </Flex>
          <Box className="w-3/5 flex">
            <Flex className="w-fit divide-y" column>
              {captions.map((caption) => (
                <Text className="py-5 pr-5" key={caption}>
                  {t(caption)}
                </Text>
              ))}
            </Flex>
            <Flex className="flex-1 divide-y" column>
              {values.map((value) => value)}
            </Flex>
          </Box>
        </Flex>
      )}
      <EditCustomerAlertsModal
        isOpenedModal={isCustomerEditAlertOpened}
        isSelectAllSelected={isSelectAllSelected}
        isUpdateLoading={updateContractSettingLoading}
        onSubmit={handleChangeLienAlert}
        selectedCustomer={selectedCustomer}
        selectedCustomers={selectedCustomers}
        setIsOpenedModal={setIsCustomerEditAlertOpened}
      />

      {!contractData && (
        <Dialog
          contentClass="w-248"
          isOpened={isGeneralSettingsOpened}
          setIsOpened={setIsGeneralSettingsOpened}
          title={t('generalSettings')}
          titleClass="text-2xl font-semibold pl-3">
          <EditGeneralSettingsForm
            generalSettings={generalSettings}
            setIsOpenedModal={setIsGeneralSettingsOpened}
          />
        </Dialog>
      )}
    </>
  )
}

LienSettings.propTypes = {
  contractData: contractDataShape,
  isGeneralSettingsOpened: PropTypes.bool,
  setIsGeneralSettingsOpened: PropTypes.func.isRequired,
}

export default LienSettings
