import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { contractDataShape } from '../../../types/invoiceShapes'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { alertsAvailableFilters } from './mocks'
import { getPaginationData, parseFilterQuery } from '../../../utils/utils'
import { useQueryParams } from '../../../hooks/useQueryParams'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import Sidebar from '../../../ui-kit/components/sidebar/Sidebar'
import { camelCase, toInteger } from 'lodash'
import { statesList } from '../../../utils/statesList'
import { Flex, Money, Text } from '../../../ui-kit'
import FiltersControlButton from '../../../components/filters/FiltersControlButton'
import { DateTime } from 'luxon'
import { getDifDays, SERVER_DATE_FORMAT } from '../../../ui-kit/utils/dateUtils'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { getLoadingFunc } from '../../invoices/invoicesUtils'
import { ContractsAutocompleteQuery } from '../../../queries/contracts.gql'
import { LienMonitorAlertsQuery } from '../../../queriesUpdated/queries/lienMonitorSearchResults.gql'
import SidebarLienContent from '../finder/SidebarLienContent'
import { useOpenEntitySidebar } from '../../../hooks/useOpenEntitySidebar'

export const checkIfNew = (date) => {
  const today = DateTime.now()
  const luxonDate = DateTime.fromFormat(date, SERVER_DATE_FORMAT)
  return getDifDays(today, luxonDate) < 8
}

const LienAlerts = ({ contractData }) => {
  const { t } = useTranslation()
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [selectedLien, setSelectedLien] = useState({})
  const { queryParams } = useQueryParams()
  const sort = queryParams.sort || 'created_at.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  const filtersQuery = queryParams.filters || null
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  const pageSize = 100
  const queryVariables = useMemo(
    () => ({
      filters: [
        ...userFilters,
        ...(contractData
          ? [
              {
                key: 'contract_id',
                values: [contractData.id],
              },
            ]
          : []),
      ],
      sort,
      page,
      search,
      perPage: pageSize,
    }),
    [sort, page, search, pageSize, contractData],
  )
  const { data, loading } = useCustomQuery({
    query: LienMonitorAlertsQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: {
      operationName: 'LienMonitorAlertsQuery',
      target: 'LienAlerts',
    },
  })

  const rows = data?.lienMonitorSearchResults?.data || []
  const paginationData = getPaginationData(data?.lienMonitorSearchResults)

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    queryOptions: {
      skip: !!contractData,
    },
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'LienAlerts' },
  })
  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('id'),
        flex: 0.5,
        filterId: additionalFilters.includes('id') && 'id',
      },
      {
        field: 'createdAt',
        headerName: t('alertDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
        width: 150,
        filterId: 'createdAt',
        filterTitle: t('alertDate'),
      },
      {
        field: 'isNew',
        headerName: '',
        renderCell: (values) =>
          checkIfNew(values?.row?.createdAt) ? <Text color="text-orange-700">{t('new')}</Text> : '',
        width: 60,
      },
      {
        field: 'customerName',
        headerName: t('customerName'),
        renderCell: (values) => <Money value={values?.row?.contract?.buyer?.name} />,
        flex: 2,
        filterId: !contractData && 'contractId',
        filterTitle: !contractData && t('customer'),
        loadOptions: loadContractOptions,
        hide: !!contractData,
      },
      {
        field: 'recordedDate',
        headerName: t('filingDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.recordedDate} />,
        width: 150,
        filterId: 'recordedDate',
      },
      {
        field: 'filingState',
        headerName: t('filingState'),
        renderCell: (values) => values?.row?.projectState,
        flex: 1,
        filterId: 'filingState',
        filterTitle: t('state'),
        filterOptions: statesList.map((state) => ({
          key: state.value,
          title: state.label,
        })),
      },
      {
        field: 'claimAmount',
        headerName: t('claimAmount'),
        renderCell: (values) =>
          values?.row?.claimAmount ? <Money value={values?.row?.claimAmount * 100} /> : t('nA'),
        flex: 1,
      },
    ],
    [],
  )

  const handleRowClick = (model) => {
    setSelectedLien(model?.row)
    setIsSidebarOpened(true)
  }

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])
  useOpenEntitySidebar(rows, setSelectedLien, setIsSidebarOpened)

  return (
    <>
      <Flex justifyContent="end">
        <FiltersControlButton />
      </Flex>
      <div className="flex pt-4">
        <DataGridComponent
          availableFilters={alertsAvailableFilters}
          columns={columns}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rowClassName="cursor-pointer"
          rowClick={handleRowClick}
          rows={rows}
          searchLabel={t('lienAlerts')}
          sortModel={defaultSortModel}
        />
      </div>
      <Sidebar
        clearSelectedInvoice={() => setSelectedLien({})}
        isSidebarOpened={isSidebarOpened}
        setIsSidebarOpened={setIsSidebarOpened}>
        <SidebarLienContent data={selectedLien} />
      </Sidebar>
    </>
  )
}

LienAlerts.propTypes = {
  contractData: contractDataShape,
}

export default LienAlerts
