/*eslint-disable react/prop-types*/
/*eslint-disable react/display-name*/
import React, { useCallback, useRef, useState } from 'react'
import ReactSelect, { components } from 'react-select'
import Checkbox from '../inputs/Checkbox'
import customStyles from './styles'
import styles from './Select.module.scss'
import { Icon, Flex, Tooltip } from '../../index'
import colors from '../../colors'
import PT from 'prop-types'

/*
 * testData adding guide
 * https://gist.github.com/MikaelCarpenter/1fe226967b00eea82e6a4760b244ada1
 * */
const getMenuListWithChildren = (children, ref) => (props) => {
  return (
    <components.MenuList
      {...props}
      className={styles.menuList}
      innerRef={(el) => {
        props.innerRef(el)
        ref.current = el
      }}>
      {props.children}
      {children}
    </components.MenuList>
  )
}

const getDropdown = (customIndicator) => (props) => {
  return (
    <components.DropdownIndicator
      {...props}
      innerProps={{
        ...props.innerProps,
        testData: `dropdown-${props.selectProps.inputProps.testData}-indicator`,
      }}>
      <Icon
        className={`${open ? 'text-gray-300' : 'text-gray-500'} w-10 h-5`}
        {...props}
        color={open ? '#C4C8CF' : null}
        name={customIndicator ? customIndicator : open ? 'chevronUp' : 'chevronDown'}
      />
    </components.DropdownIndicator>
  )
}

const CheckboxOption = (props) => (
  <components.Option {...props} getStyles={() => null}>
    <Checkbox id={props.value} isChecked={props.isSelected} onChange={() => null} isDisabled>
      {props.label}
    </Checkbox>
  </components.Option>
)

const getMenuPlaceholder = (withCounterBadge) => (props) => {
  const selectedOptionsCount = props.getValue().length
  return (
    <components.Placeholder {...props}>
      {props.children}{' '}
      {withCounterBadge && selectedOptionsCount > 0 && <div>{selectedOptionsCount}</div>}
    </components.Placeholder>
  )
}

const getSimpleOption = (withCheckmarks, testData) => (props) => {
  const [hover, setHover] = useState(false)
  const hoverStyles = hover
    ? {
        backgroundColor: '#F8F7FC',
        color: 'black',
      }
    : null
  const optionStyles = {
    ...props.getStyles('option', props),
    ...hoverStyles,
  }
  return (
    <div
      onClick={props.innerProps.onClick}
      {...props.innerProps}
      id={props.innerProps.id}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      style={optionStyles}
      tabIndex={props.innerProps.tabIndex}
      testData={`dropdown-${testData}-option`}>
      {props.isSelected && withCheckmarks && (
        <Icon color={colors.PRIMARY} name="check" style={{ position: 'absolute', left: '14px' }} />
      )}
      {props.children}
    </div>
  )
}

const Menu = (props) => {
  return (
    <components.Menu
      {...props}
      className={styles.menu}
      innerProps={{
        ...props.innerProps,
        testData: `dropdown-${props.selectProps.inputProps.testData}-menu`,
      }}
    />
  )
}
const SingleValue = (props) => {
  return (
    <components.SingleValue
      {...props}
      innerProps={{
        ...props.innerProps,
        testData: `dropdown-${props.selectProps.inputProps.testData}-selected`,
      }}>
      {props.getValue()[0].label}
    </components.SingleValue>
  )
}

const Control = (props) => {
  return (
    <components.Control
      className={styles.control}
      {...props}
      innerProps={{
        ...props.innerProps,
        testData: `dropdown-${props.selectProps.inputProps.testData}-control`,
      }}>
      {props.children}
    </components.Control>
  )
}
const getFormattedSingleValue =
  (formatValue) =>
  // eslint-disable-next-line no-unused-vars
  ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {formatValue(props.getValue()[0].label)}
      </components.SingleValue>
    )
  }
const NoNoOptionsMessage = () => {
  return null
}
const SeoInput = (props) => {
  return (
    <components.Input
      {...props}
      aria-label={props.name}
      aria-labelledby={props.name}
      testData={`dropdown-${props.selectProps.inputProps.testData}-input`}
    />
  )
}

const Select = ({
  placeholder,
  isClearable,
  withCheckboxes,
  withCheckmarks,
  classNamePrefix,
  withPortal,
  children,
  isMulti,
  formatOptionLabel,
  formatValue,
  className,
  isLoading,
  error,
  touched,
  outerStyles,
  components,
  onChange,
  customDropdownIndicator,
  value,
  style,
  id,
  label,
  withCounterBadge,
  isDisabled,
  selectRef,
  testData,
  hint,
  ...props
}) => {
  const [localMenuIsOpen, setMenuIsOpen] = useState(false)
  const childrenRef = useRef(null)
  const processBlur = useCallback(
    (event) => {
      if (children && childrenRef.current?.contains?.(event.nativeEvent.relatedTarget)) {
        setMenuIsOpen(true)
        event.nativeEvent.relatedTarget.addEventListener('blur', () => event.target.focus(), {
          once: true,
        })
      } else {
        setMenuIsOpen(false)
      }
    },
    [childrenRef, children],
  )
  return (
    <>
      {label && (
        <Flex className="gap-x-1">
          <label
            className={'mb-[0.3rem] block text-sm font-medium text-gray-700 cursor-pointer'}
            htmlFor={id}
            onClick={() => setMenuIsOpen(true)}>
            {label}
          </label>
          {hint && (
            <Tooltip content={hint}>
              <Icon color="text-warmBlack-400" name="informationCircle" type="outline" />
            </Tooltip>
          )}
        </Flex>
      )}
      <ReactSelect
        {...props}
        className={className}
        classNamePrefix={classNamePrefix}
        closeMenuOnSelect={!isMulti}
        components={{
          ...components,
          ...{ DropdownIndicator: getDropdown(customDropdownIndicator) },
          Option: withCheckboxes ? CheckboxOption : getSimpleOption(withCheckmarks, testData),
          ...(children && {
            MenuList: getMenuListWithChildren(children, childrenRef),
          }),
          ...(children && {
            Placeholder: getMenuPlaceholder(withCounterBadge),
          }),
          Menu,
          Control,
          ...(children && { NoOptionsMessage: NoNoOptionsMessage }),
          Input: SeoInput,
          ...(formatValue
            ? { SingleValue: getFormattedSingleValue(formatValue) }
            : { SingleValue: SingleValue }),
        }}
        formatOptionLabel={formatOptionLabel}
        hideSelectedOptions={false}
        inputId={id}
        inputProps={{ 'aria-label': props.name, 'aria-labelledby': props.name, testData }}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        menuIsOpen={children ? localMenuIsOpen : undefined}
        menuPortalTarget={withPortal ? document.body : undefined}
        meta={{ error: error }}
        onBlur={processBlur}
        onChange={onChange}
        onFocus={() => {
          if (children) {
            setMenuIsOpen(true)
          }
        }}
        placeholder={placeholder}
        ref={selectRef}
        styles={outerStyles ? { ...outerStyles, ...style } : { ...customStyles }}
        value={value}
      />
      {touched && error && <div className={styles.error}>{error}</div>}
    </>
  )
}

export default Select

Select.propTypes = {
  placeholder: PT.string,
  isClearable: PT.bool,
  withCheckboxes: PT.bool,
  withCheckmarks: PT.bool,
  classNamePrefix: PT.string,
  withPortal: PT.bool,
  children: PT.node,
  isMulti: PT.bool,
  formatOptionLabel: PT.func,
  formatValue: PT.func,
  className: PT.string,
  isLoading: PT.bool,
  error: PT.bool,
  touched: PT.bool,
  outerStyles: PT.shape({}),
  components: PT.node,
  onChange: PT.func.isRequired,
  customDropdownIndicator: PT.node,
  withCounterBadge: PT.bool,
  value: PT.oneOfType([PT.string, PT.number]),
  style: PT.string,
  id: PT.string,
  label: PT.string,
  testData: PT.string,
}

Select.defaultProps = {
  placeholder: undefined,
  isClearable: false,
  withCheckboxes: false,
  withCheckmarks: false,
  classNamePrefix: '',
  withPortal: false,
  children: null,
  isMulti: false,
  formatOptionLabel: null,
  formatValue: null,
  className: '',
  isLoading: false,
  error: false,
  touched: false,
  outerStyles: null,
  components: {},
  customDropdownIndicator: null,
  style: undefined,
  label: undefined,
  withCounterBadge: false,
  testData: '',
}
