import { Button } from '../../../ui-kit'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { UpdateVendorMutation } from '../../../queries/mutations/updateVendorRequest.gql'
import { VendorSettingsQuery } from '../../../queries/vendors.gql'
import PT from 'prop-types'
import { useNotifications } from '../../../hooks/useNotifications'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { companyInformationModes } from './modes'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import Textarea from '../../../ui-kit/components/inputs/Textarea'

const CustomerPortalAnnouncementEditForm = ({ vendor, setMode }) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()

  const initialValues = {
    customerPortalAnnouncement: vendor.customerPortalAnnouncement,
  }

  const onCompleted = useCallback(() => {
    setMode(companyInformationModes.VIEW)
  }, [])
  const [update, { loading }] = useCustomMutation({
    mutation: UpdateVendorMutation,
    onCompleted,
    rollbarOptions: {
      operationName: 'UpdateVendorMutation',
      target: 'CustomerPortalAnnouncementEditForm',
    },
    mutationOptions: {
      refetchQueries: [VendorSettingsQuery],
    },
  })

  const onSubmit = (values) => {
    const data = {
      customerPortalAnnouncement: values.customerPortalAnnouncement?.trim() || '',
    }
    const variables = {
      data,
      id: vendor.id,
    }

    update({ variables }).then(({ data }) => {
      const responseData = data?.updateVendor || {}

      if (responseData?.entity) {
        newNotification({ success: t('informationWasUpdated') })
      }
    })
  }

  const closeForm = useCallback(() => {
    setMode(companyInformationModes.VIEW)
  }, [setMode])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <div className="w-full">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirty }) => {
          useBeforeUnload({ when: dirty })
          dirty !== isFormDirty && setDirtyFormState(dirty)

          return (
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className="w-full">
                <Field name="customerPortalAnnouncement">
                  {({ input, meta }) => {
                    return (
                      <Textarea
                        className="flex-1"
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        inputClassName="h-44"
                        label={t('customerPortalAnnouncement')}
                        placeholder={t('customerPortalAnnouncementDescription')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 flex flex-row justify-end">
                <Button
                  className="mr-4"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="close-edit-company"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  disabled={loading}
                  label={t('save')}
                  testData="save-company-info"
                  type="submit"
                />
              </div>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
      />
    </div>
  )
}

CustomerPortalAnnouncementEditForm.propTypes = {
  setMode: PT.func,
  vendor: PT.shape({
    billingAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      street2: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    id: PT.oneOfType([PT.string, PT.number]),
    officeAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      street2: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    name: PT.string,
    website: PT.string,
    supportEmail: PT.string,
    supportPhoneNumber: PT.string,
    customerPortalAnnouncement: PT.string,
  }).isRequired,
}

export default CustomerPortalAnnouncementEditForm
