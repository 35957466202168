import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import { contractDataShape } from '../../../types/invoiceShapes'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { getActiveTab } from '../../../utils/utils'
import { Box, Button, Flex, Tabs } from '../../../ui-kit'
import LienAlerts from './LienAlerts'
import PageHeader from '../../../components/headers/PageHeader'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import LienSettings from './LienSettings'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { isCounterUser } from '../../settings/teamManagement/membershipRoles'

const LienAlert = ({ contractData }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const currentUser = useCurrentUser()
  const { setBreadcrumbs } = useBreadcrumbs()
  const [activeTab, setActiveTab] = useState('')
  const [isGeneralSettingsOpened, setIsGeneralSettingsOpened] = useState(false)

  const handleTabClick = (tab) => {
    navigate(`./${tab}`)
    setActiveTab(tab)
  }
  const tabs = ['lienAlerts', 'lienSettings']
  const tabsMap = tabs.map((tab) => ({
    label: t(tab),
    onClick: () => handleTabClick(tab),
    active: activeTab === tab,
  }))

  useEffect(() => {
    const breadcrumbs = contractData?.id
      ? [
          { label: t('customers'), link: '/customers' },
          contractData?.buyer
            ? { label: contractData?.buyer?.name, link: `/customers/${contractData.id}/overview` }
            : null,
          { label: t('liens'), link: `/customers/${contractData.id}/liens` },
          { label: t('lienAlert') },
        ]
      : [{ label: t('liens'), link: '/liens' }, { label: t('lienAlert') }]
    setBreadcrumbs(breadcrumbs)
  }, [contractData])

  useEffect(() => {
    setActiveTab(getActiveTab(location, contractData ? 'liens' : 'alert') || 'lienAlerts')
  }, [location])

  useEffect(() => {
    if (
      isCounterUser(currentUser) ||
      (currentUser?.featureFlags && !currentUser.featureFlags.lienMonitor?.enabledForActor)
    ) {
      navigate(contractData?.id ? `/customers/${contractData.id}/overview` : '/dashboard')
    }
  }, [currentUser, contractData])

  return (
    <>
      {contractData && <PageHeader headerTitle={t('liens')} />}
      <Box className="flex-1">
        <Flex alignItems="center" justifyContent="between">
          <Tabs tabs={tabsMap} />
          {!contractData && activeTab === tabs[1] && (
            <Flex justifyContent="end">
              <Button
                label={t('generalSettings')}
                onClick={() => setIsGeneralSettingsOpened(true)}
                variant={buttonsVariants.SECONDARY}
              />
            </Flex>
          )}
        </Flex>
        <Routes>
          <Route element={<Navigate to="lienAlerts" replace />} path="/" />
          <Route element={<LienAlerts contractData={contractData} />} path="/lienAlerts" />
          <Route
            element={
              <LienSettings
                contractData={contractData}
                isGeneralSettingsOpened={isGeneralSettingsOpened}
                setIsGeneralSettingsOpened={setIsGeneralSettingsOpened}
              />
            }
            path="/lienSettings"
          />
        </Routes>
      </Box>
    </>
  )
}

LienAlert.propTypes = {
  contractData: contractDataShape,
}

export default LienAlert
