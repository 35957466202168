import React from 'react'
import { get } from 'lodash'
import { getPaginationData } from '../../../../utils/utils'
import { DateTime } from 'luxon'
import FormattedDate from '../../../../ui-kit/components/text/FormattedDate'
import { Button, Money, Text } from '../../../../ui-kit'
import sizes from '../../../../ui-kit/sizes'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import StatusBadge from '../../../../ui-kit/components/badges/StatusBadge'
import { getDisplayStatusValue, PAYMENT, paymentTypeMapping } from '../../../contracts/utils'
import colors from '../../../../ui-kit/colors'
import { displayStatusesMapping } from '../../../payments/paymentsTab/util'
import { statusP2PMapping } from '../../../payments/promiseToPay/util'
import { getLienWaiverRecentEventRowItem } from './getLienWaiverRecentEventRowItem'
import { checkIfNew } from '../../../liens/alert/LienAlerts'
import { getDateRange } from '../../../../ui-kit/components/datePicker/logic'

export const getTotalsButtons = ({ featureFlags } = {}) => ({
  paymentIssues: {
    key: 'paymentIssues',
    label: 'paymentIssues',
    boxLabel: 'newPaymentIssues',
    iconName: 'cash',
  },
  disputes: {
    key: 'disputes',
    label: 'disputes',
    iconName: 'chatAlt2',
  },
  brokenPaymentPromises: {
    key: 'brokenPaymentPromises',
    label: 'brokenPaymentPromises',
    boxLabel: 'newExpPromises',
    iconName: 'exclamation',
  },
  lienAlerts: {
    key: 'lienAlerts',
    label: 'lienAlerts',
    iconName: 'exclamation',
    hidden: !featureFlags?.lienMonitor?.enabledForActor,
  },
  lienWaivers: {
    key: 'lienWaivers',
    label: 'lienWaivers',
    iconName: 'clipboardList',
  },
})

export const getRecentEventsTotalsQueryParams = (currentUser) => ({
  vendorId: get(currentUser, 'vendorId'),
})

export const getRecentEventsTotals = (recentEventsTotalsData) =>
  recentEventsTotalsData?.recentEventsTotals || {}

export const handleRecentEventsItemClick = ({
  event,
  setSelectedEvent,
  currentUser,
  createOrUpdateUserView,
  totalsButtons,
}) => {
  if (!createOrUpdateUserView) {
    return
  }

  const targetEvent = event?.currentTarget?.id
  setSelectedEvent?.(targetEvent)
  const payload = {
    dashboardPaymentIssues: targetEvent === totalsButtons.paymentIssues.key,
    dashboardBrokenPaymentPromises: targetEvent === totalsButtons.brokenPaymentPromises.key,
  }

  if (!payload.dashboardPaymentIssues && !payload.dashboardBrokenPaymentPromises) {
    return
  }

  const variables = {
    data: payload,
    vendorId: currentUser?.vendorId,
  }

  createOrUpdateUserView({ variables })
}

export const getRecentEventsInvoicesQueryParams = (page) => ({
  filters: [
    {
      key: 'has_action_entity',
      values: ['has_ongoing_dispute'],
    },
  ],
  page,
})

export const getRecentEventsPaymentTransactionsQueryParams = (page) => ({
  filters: [
    {
      key: 'payment_issue_date',
      values: [DateTime.now().minus({ day: 7 }).toISO()],
    },
  ],
  sort: 'created_at.desc',
  page,
})

export const getRecentEventsPaymentPromisesQueryParams = (page) => ({
  filters: [
    {
      key: 'status',
      values: ['expired'],
    },
    {
      key: 'outstanding_amount',
      values: ['1'],
    },
    {
      key: 'date',
      values: [DateTime.now().minus({ day: 7 }).toISO()],
    },
  ],
  sort: 'created_at.desc',
  page,
})

export const getRecentEventsLienWaiversQueryParams = (page) => ({
  sort: 'created_at.desc',
  page,
})

export const handleDismissLienWaiver = async (id, dismissLienWaiver, t, newNotification) => {
  if (!dismissLienWaiver) {
    return
  }

  const variables = {
    id,
  }

  const { data } = await dismissLienWaiver({ variables })
  const responseData = data?.dismissLienWaiver || {}

  if (responseData?.entity) {
    newNotification({ success: t('lienWaiverDismissedSuccessfully') })
  }
}

export const getDataSourceLoadingState = (
  totalsButtons,
  selectedEvent,
  disputesDataLoading,
  paymentTransactionsDataLoading,
  paymentPromisesDataLoading,
  lienWaiversDataLoading,
  lienAlertsDataLoading,
) => {
  if (!totalsButtons) {
    return false
  }

  switch (selectedEvent) {
    case totalsButtons.disputes.key:
      return disputesDataLoading
    case totalsButtons.paymentIssues.key:
      return paymentTransactionsDataLoading
    case totalsButtons.brokenPaymentPromises.key:
      return paymentPromisesDataLoading
    case totalsButtons.lienWaivers.key:
      return lienWaiversDataLoading
    case totalsButtons.lienAlerts.key:
      return lienAlertsDataLoading
    default:
      return false
  }
}

export const getDataSourceData = (
  totalsButtons,
  selectedEvent,
  disputesData,
  paymentTransactionsData,
  paymentPromisesData,
  lienWaiversData,
  lienAlertsData,
) => {
  if (!totalsButtons) {
    return []
  }

  switch (selectedEvent) {
    case totalsButtons.disputes.key:
      return get(disputesData, 'invoices.data', [])
    case totalsButtons.paymentIssues.key:
      return get(paymentTransactionsData, 'paymentTransactions.data', [])
    case totalsButtons.brokenPaymentPromises.key:
      return get(paymentPromisesData, 'paymentPromises.data', [])
    case totalsButtons.lienWaivers.key:
      return get(lienWaiversData, 'lienWaivers.data', []).reduce((acc, lienWaiver) => {
        const rowItem = getLienWaiverRecentEventRowItem(lienWaiver)

        if (rowItem) {
          acc.push(rowItem)
        }

        return acc
      }, [])
    case totalsButtons.lienAlerts.key:
      return get(lienAlertsData, 'lienMonitorSearchResults.data', [])
    default:
      return []
  }
}

export const getDataSourceColumns = (totalsButtons, selectedEvent, t, navigate) => {
  if (!totalsButtons || !t || !navigate) {
    return []
  }

  switch (selectedEvent) {
    case totalsButtons.disputes.key:
      return [
        {
          field: 'createdAt',
          headerName: t('createDate'),
          renderCell: (values) => <FormattedDate date={values?.row?.dispute?.createdAt} />,
          flex: 10,
          sortable: false,
        },
        {
          field: 'id',
          headerName: t('id'),
          renderCell: (values) => {
            const disputeCreateEvent = (values?.row?.activities || []).find(
              (event) =>
                event.eventEntity === 'dispute' &&
                event.eventAction === 'create' &&
                values?.row?.dispute?.id === event.entity?.disputeId,
            )
            const eventId = disputeCreateEvent?.id
            return (
              <Button
                label={values?.row?.dispute?.id}
                onClick={() =>
                  navigate(
                    // eslint-disable-next-line max-len
                    `/customers/${values?.row?.contract?.id}/activity?additionalFilters=id&filters=id.${eventId}&openSidebarParams=id.${eventId}`,
                  )
                }
                size={sizes.SM}
                variant={buttonsVariants.LINK}
              />
            )
          },
          flex: 10,
          sortable: false,
        },
        {
          field: 'customerName',
          headerName: t('customer'),
          renderCell: (values) => (
            <Button
              label={values?.row?.contract?.buyer?.name}
              onClick={() => navigate(`/customers/${values?.row?.contract?.id}/overview`)}
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 20,
          sortable: false,
        },
        {
          field: 'invoiceNumber',
          headerName: t('invoiceNumber'),
          renderCell: (values) => (
            <Button
              label={values?.row?.invoiceNumber}
              onClick={() =>
                navigate(
                  `/customers/${values?.row?.contract?.id}/invoices/${
                    values?.row?.outstandingAmountCents > 0 ? 'outstanding' : 'paid'
                  }?additionalFilters=invoicedNumber&filters=invoicedNumber.${
                    values?.row?.invoiceNumber
                  }&openSidebarParams=invoiceNumber.${values?.row?.invoiceNumber}`,
                )
              }
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 10,
          sortable: false,
        },
        {
          field: 'outstandingAmountCents',
          headerName: t('outstanding'),
          renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
          flex: 10,
          align: 'right',
          headerAlign: 'right',
          sortable: false,
        },
        {
          field: 'status',
          headerName: t('status'),
          renderCell: (values) =>
            values?.row?.outstandingAmountCents ? (
              <StatusBadge
                color={values?.row?.status}
                iconName="dot"
                value={getDisplayStatusValue(values?.row?.overdueLevel)}
              />
            ) : (
              <StatusBadge color={colors.GREEN} label={t('paid')} />
            ),
          flex: 10,
          sortable: false,
        },
      ]
    case totalsButtons.paymentIssues.key:
      return [
        {
          field: 'createdAt',
          headerName: t('createDate'),
          renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
          flex: 10,
          sortable: false,
        },
        {
          field: 'id',
          headerName: t('id'),
          renderCell: (values) => (
            <Button
              label={values?.row?.id}
              onClick={() =>
                navigate(
                  // eslint-disable-next-line max-len
                  `/customers/${values?.row?.payable?.contract?.id}/payments/payments?additionalFilters=id&filters=id.${values?.row?.id}&openSidebarParams=id.${values?.row?.id}`,
                )
              }
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 10,
          sortable: false,
        },
        {
          field: 'customerName',
          headerName: t('customer'),
          renderCell: (values) => (
            <Button
              label={values?.row?.payable?.contract?.buyer?.name}
              onClick={() => navigate(`/customers/${values?.row?.payable?.contract?.id}/overview`)}
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 20,
          sortable: false,
        },
        {
          field: 'amount',
          headerName: t('amount'),
          renderCell: (values) => (
            <Money
              value={
                values.row?.payableType === PAYMENT
                  ? values.row?.payable?.totalAmountCents
                  : values.row?.payable?.amountCents
              }
            />
          ),
          flex: 10,
          align: 'right',
          headerAlign: 'right',
          sortable: false,
        },
        {
          field: 'payableType',
          headerName: t('paymentType'),
          renderCell: (values) => t(paymentTypeMapping[values?.row?.payableType]),
          flex: 10,
          sortable: false,
        },
        {
          field: 'paymentMethod',
          headerName: t('paymentMethod'),
          flex: 20,
          renderCell: (values) => values?.row?.paymentMethod?.title,
          sortable: false,
        },
        {
          field: 'status',
          headerName: t('status'),
          flex: 10,
          renderCell: (values) => (
            <StatusBadge
              color={displayStatusesMapping[values?.row?.displayStatus]?.color}
              value={t(displayStatusesMapping[values?.row?.displayStatus]?.label)}
            />
          ),
          sortable: false,
        },
      ]
    case totalsButtons.brokenPaymentPromises.key:
      return [
        {
          field: 'date',
          headerName: t('promiseDate'),
          renderCell: (values) => <FormattedDate date={values?.row?.date} />,
          flex: 10,
          sortable: false,
        },
        {
          field: 'id',
          headerName: t('id'),
          renderCell: (values) => (
            <Button
              label={values?.row?.id}
              onClick={() =>
                navigate(
                  // eslint-disable-next-line max-len
                  `/customers/${values?.row?.contract?.id}/payments/promisesToPay?additionalFilters=id&filters=id.${values?.row?.id}&openSidebarParams=id.${values?.row?.id}`,
                )
              }
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 10,
          sortable: false,
        },
        {
          field: 'customerName',
          headerName: t('customer'),
          renderCell: (values) => (
            <Button
              label={values?.row?.contract?.buyer?.name}
              onClick={() => navigate(`/customers/${values?.row?.contract?.id}/overview`)}
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 20,
          sortable: false,
        },
        {
          field: 'outstanding',
          headerName: t('outstanding'),
          renderCell: (values) => (
            <Money
              value={(values.row?.invoices || []).reduce(
                (acc, invoice) => acc + invoice.outstandingAmountCents,
                0,
              )}
            />
          ),
          flex: 10,
          align: 'right',
          headerAlign: 'right',
          sortable: false,
        },
        {
          field: 'status',
          headerName: t('status'),
          flex: 10,
          renderCell: (values) => (
            <StatusBadge
              color={statusP2PMapping[values?.row?.status]?.color}
              value={t(statusP2PMapping[values?.row?.status]?.label)}
            />
          ),
          sortable: false,
        },
      ]
    case totalsButtons.lienWaivers.key:
      return [
        {
          field: 'lienWaiverCreatedAt',
          headerName: t('requestDate'),
          renderCell: (values) => <FormattedDate date={values?.row?.lienWaiverCreatedAt} />,
          flex: 12,
          sortable: false,
        },
        {
          field: 'customerName',
          headerName: t('customer'),
          renderCell: (values) => (
            <Button
              label={values?.row?.contract?.buyer?.name}
              onClick={() => navigate(`/customers/${values?.row?.contract?.id}/overview`)}
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 18,
          sortable: false,
        },
        {
          field: 'projectName',
          headerName: t('project'),
          renderCell: (values) => (
            <Button
              label={values?.row?.project?.name}
              onClick={() =>
                navigate(
                  // eslint-disable-next-line max-len
                  `/customers/${values?.row?.contract?.id}/projects/${values?.row?.project?.status}?additionalFilters=id&filters=id.${values?.row?.project?.id}&openSidebarParams=id.${values?.row?.project?.id}`,
                )
              }
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 17,
          sortable: false,
        },
        {
          field: 'invoiceNumber',
          headerName: t('invNumber'),
          renderCell: (values) => (
            <Button
              label={values?.row?.invoiceNumber}
              onClick={() =>
                navigate(
                  `/customers/${values?.row?.contract?.id}/invoices/${
                    values?.row?.outstandingAmountCents > 0 ? 'outstanding' : 'paid'
                  }?additionalFilters=invoicedNumber&filters=invoicedNumber.${
                    values?.row?.invoiceNumber
                  }&openSidebarParams=invoiceNumber.${values?.row?.invoiceNumber}`,
                )
              }
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 15,
          sortable: false,
        },
        {
          field: 'invoicePaidAmountCents',
          headerName: t('amountPaid'),
          renderCell: (values) => <Money value={values.row?.invoicePaidAmountCents} />,
          flex: 10,
          align: 'right',
          headerAlign: 'right',
          sortable: false,
        },
        {
          field: 'invoiceOutstandingAmountCents',
          headerName: t('amountOS'),
          renderCell: (values) => <Money value={values.row?.outstandingAmountCents} />,
          flex: 10,
          align: 'right',
          headerAlign: 'right',
          sortable: false,
        },
        {
          field: 'displayStatus',
          headerName: t('pmtStatus'),
          flex: 10,
          renderCell: (values) => (
            <StatusBadge
              color={displayStatusesMapping[values?.row?.paymentDisplayStatus]?.color}
              value={t(displayStatusesMapping[values?.row?.paymentDisplayStatus]?.label)}
            />
          ),
          sortable: false,
        },
      ]
    case totalsButtons.lienAlerts.key:
      return [
        {
          field: 'id',
          headerName: t('id'),
          renderCell: (values) => (
            <Button
              label={values?.row?.id}
              onClick={() =>
                navigate(
                  // eslint-disable-next-line max-len
                  `/liens/alert/lienAlerts?additionalFilters=id&filters=id.${values?.row?.id}&openSidebarParams=id.${values?.row?.id}`,
                )
              }
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 0.5,
          sortable: false,
        },
        {
          field: 'date',
          headerName: t('alertDate'),
          renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
          width: 150,
          sortable: false,
        },
        {
          field: 'isNew',
          headerName: '',
          renderCell: (values) =>
            checkIfNew(values?.row?.createdAt) ? (
              <Text color="text-orange-700">{t('new')}</Text>
            ) : (
              ''
            ),
          width: 60,
          sortable: false,
        },
        {
          field: 'customerName',
          headerName: t('customerName'),
          renderCell: (values) => (
            <Button
              label={values?.row?.contract?.buyer?.name}
              onClick={() => navigate(`/customers/${values?.row?.contract?.id}/overview`)}
              size={sizes.SM}
              variant={buttonsVariants.LINK}
            />
          ),
          flex: 2,
          sortable: false,
        },
        {
          field: 'recordedDate',
          headerName: t('filingDate'),
          renderCell: (values) => <FormattedDate date={values?.row?.recordedDate} />,
          width: 150,
          sortable: false,
        },
        {
          field: 'projectState',
          headerName: t('filingState'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'claimAmount',
          headerName: t('claimAmount'),
          renderCell: (values) =>
            values?.row?.claimAmount ? (
              <Money value={values?.row?.claimAmount * 100} />
            ) : (
              <Text>{t('nA')}</Text>
            ),
          flex: 1,
          sortable: false,
        },
      ]
    default:
      return []
  }
}

export const getDataSourcePagination = (
  totalsButtons,
  selectedEvent,
  disputesData,
  paymentTransactionsData,
  paymentPromisesData,
  lienWaiversData,
  lienAlertsData,
) => {
  let model = void 0

  if (!totalsButtons) {
    return getPaginationData(model)
  }

  switch (selectedEvent) {
    case totalsButtons.disputes.key:
      model = get(disputesData, 'invoices')
      break
    case totalsButtons.paymentIssues.key:
      model = get(paymentTransactionsData, 'paymentTransactions')
      break
    case totalsButtons.brokenPaymentPromises.key:
      model = get(paymentPromisesData, 'paymentPromises')
      break
    case totalsButtons.lienWaivers.key:
      model = get(lienWaiversData, 'lienWaivers')
      break
    case totalsButtons.lienAlerts.key:
      model = get(lienAlertsData, 'lienMonitorSearchResults')
      break
  }

  return getPaginationData(model)
}

export const getRecentEventsLienAlertsQueryParams = (page) => ({
  filters: [
    {
      key: 'alert_date',
      values: getDateRange('minus', { days: 7 }).map((date) => DateTime.fromJSDate(date).toISO()),
    },
  ],
  page,
})
