import { camelCase } from 'lodash/string'

export const getFeatureFlags = (featureFlags = []) =>
  featureFlags.reduce(
    (acc, featureFlagConfig) => ({
      ...acc,
      [camelCase(featureFlagConfig.name)]: {
        ...featureFlagConfig,
      },
    }),
    {},
  )
