import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Flex, Icon, Tooltip } from '../../index'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'
import { isEqual, includes } from 'lodash'
import cx from 'classnames'
import PT from 'prop-types'
import Button from '../buttons/Button'
import buttonsVariants from '../../buttonsVariants'
import { useTranslation } from 'react-i18next'
import Text from '../text/Text'
import sizes from '../../sizes'

export const StyledInput = styled(InputBase)(() => ({
  '&:focus': {
    borderColor: '#F85737',
    outline: 'none',
  },
  '&.MuiInputBase-root': {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: colors.WHITE,
    border: '1px solid #D1D5DB',
    fontSize: 14,
    fontFamily: 'Inter, sans-serif',
    padding: 0,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',

    '& .MuiInputBase-input': {
      padding: '7px 13px',
      minHeight: 'auto',
      '&.Mui-disabled': {
        ['-webkit-text-fill-color']: 'rgb(18, 18, 18)',
      },
    },
  },
}))
export const StyledHiddenInput = styled(InputBase)(() => ({
  '&.MuiInputBase-root': {
    border: 'none',
    padding: 0,
    height: '0px !important',
    minHeight: '0px !important',
    maxHeight: '0px !important',

    '& .MuiInputBase-input': {
      padding: '0px !important',
      width: '0px',
    },

    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
}))

// eslint-disable-next-line no-unused-vars
const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
      margin: 0,
    },
    '& .MuiInputBase-root': {
      borderColor: (props) => (props.errorMessage ? '#f44336' : props.open ? '#F85737' : '#D1D5DB'),
      width: '100%',
      backgroundColor: 'white',
      minHeight: '37px',
    },
    '& .MuiSelect-icon': {
      display: 'block',
      color: (props) =>
        props.errorMessage ? '#f44336' : props.open ? 'rgb(209,213,219)' : 'rgb(107,114,128)',
      transform: (props) => (props.open ? 'rotate(360deg)' : 'none'),
      top: 'calc(50% - 9px)',
    },
    opacity: (props) => (props.isDisabled ? '0.5' : '1'),
    position: 'relative',
  },
  list: {
    maxHeight: '19rem',
    '& .MuiMenuItem-root.Mui-selected': {
      backgroundColor: 'transparent',
      '& > div > div': {
        fontWeight: '600',
      },
    },
    '& .MuiMenuItem-root': {
      paddingLeft: (props) => !!props.value && props.withCheckmarks && '2rem',
      '&.Mui-focusVisible': {
        background: 'inherit',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
  paper: {
    marginTop: '3px',
    maxHeight: (props) => window.innerHeight - props.dropdownPosition - 20,
    width: 'fit-content',
    borderRadius: '6px',
    boxShadow:
      '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), ' +
      '0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
    padding: '2px 0',
  },
  button: {
    height: '37px',
  },
  selectAll: {
    width: '100%',
    cursor: 'pointer',
    padding: '6px 16px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}))

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: (props) => (props.errorMessage ? '#f44336' : '#6B7280'),
  },
}))

const Placeholder = ({ children, errorMessage, testData }) => {
  const classes = usePlaceholderStyles({ errorMessage })
  return (
    <div className={classes.placeholder} testData={`dropdown-${testData}`}>
      {children}
    </div>
  )
}

Placeholder.propTypes = {
  children: PT.node.isRequired,
  errorMessage: PT.string,
  testData: PT.string,
}

Placeholder.defaultProps = {
  errorMessage: undefined,
  testData: '',
}

export const dropdownTypes = {
  SELECT: 'SELECT',
  BUTTON: 'BUTTON',
}

// eslint-disable-next-line react/display-name
const NDropdown = forwardRef((props, ref) => {
  const {
    options,
    label,
    id,
    placeholder,
    value,
    withCheckmarks,
    isDisabled,
    onChange,
    errorMessage,
    className,
    children,
    renderValue,
    isMultipleSelect,
    isHiddenInputValue,
    defaultValue,
    type,
    buttonClassName,
    testData,
    withSingleOptionAutoSelection,
    withSelectAll,
    hint,
    ...formfields
  } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [isInitialValueApplied, setIsInitialValueApplied] = useState(false)

  useImperativeHandle(ref, () => ({
    handleExternalClose() {
      setOpen(false)
    },
  }))

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    !isDisabled && setOpen(true)
  }

  const rootRef = useRef(null)

  const dropdownPosition = rootRef?.current?.getBoundingClientRect()?.bottom || 0
  let optionsArr = []
  let menuItemPadding = ''
  let isSelectedSuboption
  const classes = useStyle({
    ...props,
    open,
    withCheckmarks,
    errorMessage,
    dropdownPosition,
    value,
    options,
  })
  const selectedValue = isMultipleSelect ? value || [] : value

  const createOptionsList = (optionsList) => {
    const options =
      (Array.isArray(optionsList) && optionsList.filter((item) => !item.isHidden)) || []

    if (options && options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        const isCurrentOptionASelectedOne = isMultipleSelect
          ? includes(selectedValue, options[i].value?.toString())
          : isEqual(selectedValue?.toString(), options[i].value?.toString())
        if (!isSelectedSuboption && isCurrentOptionASelectedOne) {
          // eslint-disable-next-line react/prop-types
          isSelectedSuboption = !!options[i].typeLabel
        }
        const optionLeftPadding = withCheckmarks && (!!value || value === 0) ? 'pl-8' : 'pl-4'

        menuItemPadding = `py-1.5 ${optionLeftPadding} pr-4`

        optionsArr.push(
          options[i].options?.length ? (
            <div className={cx('flex relative text-base', menuItemPadding)}>
              <div>
                {typeof options[i].label === 'function' ? options[i].label() : options[i].label}
              </div>
            </div>
          ) : (
            <MenuItem classes={classes.option} key={options[i].value} value={options[i].value}>
              <div
                className={`flex relative ${options[i].typeLabel && 'ml-6'}`}
                testData={`dropdown-${testData}-option-${options[i].testData}`}>
                {withCheckmarks && isCurrentOptionASelectedOne && (
                  <Icon className={'absolute -left-6'} color={colors.PRIMARY} name="check" />
                )}
                <div>
                  {typeof options[i].label === 'function' ? options[i].label() : options[i].label}
                </div>
              </div>
            </MenuItem>
          ),
        )
        createOptionsList(options[i].options)
      }
    }
  }
  createOptionsList(options)

  const renderMultiValues = (selectedOptions) => {
    const listOfValues = selectedOptions.map((option) =>
      typeof option?.label === 'function'
        ? option.label()
        : renderValue
        ? renderValue(option)
        : option?.label,
    )
    if (typeof listOfValues[0] === 'string') {
      return listOfValues.join(', ')
    }
    return listOfValues
  }

  useEffect(() => {
    if (defaultValue && !value) {
      onChange({ target: { value: defaultValue } })
    }
  }, [])

  useEffect(() => {
    if (withSingleOptionAutoSelection && isDisabled && isInitialValueApplied) {
      setIsInitialValueApplied(false)
    }

    if (!withSingleOptionAutoSelection || isInitialValueApplied) {
      return
    }

    if (!isDisabled && !value && options?.length === 1 && options?.[0]?.value) {
      onChange({ target: { value: options[0].value } })
      setIsInitialValueApplied(true)
    }
  }, [options, isDisabled])

  const input = useMemo(() => {
    switch (type) {
      case dropdownTypes.BUTTON:
        return <StyledHiddenInput />
      default:
        return <StyledInput />
    }
  }, [])

  return (
    <div className={cx(classes.root, className)} ref={rootRef}>
      {/*labelId only focus on select but it is not enough to open it*/}
      {type === dropdownTypes.SELECT && (
        <Flex className="gap-x-1">
          <label
            className={'mb-[0.3rem] block text-sm font-medium text-gray-700'}
            htmlFor={id}
            onClick={handleOpen}>
            {label}
          </label>
          {hint && (
            <Tooltip content={hint}>
              <Icon color="text-warmBlack-400" name="informationCircle" type="outline" />
            </Tooltip>
          )}
        </Flex>
      )}
      {type === dropdownTypes.BUTTON && (
        <Button
          className={cx(classes.button, buttonClassName, 'flex items-center justify-center')}
          disabled={isDisabled}
          label={label}
          onClick={handleOpen}
          variant={buttonsVariants.SECONDARY}
          fullWidth
        />
      )}
      <FormControl {...formfields} error={errorMessage}>
        <Select
          IconComponent={(props) => (
            <Icon
              className={`${open ? 'text-gray-300' : 'text-gray-500'} w-10 h-5`}
              {...props}
              color={open ? colors.LIGHTGREY : null}
              name={open ? 'chevronUp' : 'chevronDown'}
            />
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: {
              list: classes.list,
              paper: classes.paper,
            },
            MenuListProps: {
              testData: `dropdown-${testData}-menu`,
            },
          }}
          disabled={isDisabled}
          id={id}
          input={input}
          labelId="demo-controlled-open-select-label"
          multiple={isMultipleSelect}
          name={formfields.name}
          onChange={onChange}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          renderValue={
            !isHiddenInputValue && (isMultipleSelect ? value?.length : !!value || value === 0)
              ? () => {
                  const selectedOptions = options.reduce((acc, option) => {
                    const isOptionSelected = isMultipleSelect
                      ? includes(selectedValue, option.value?.toString()) ||
                        includes(selectedValue, option.value)
                      : isEqual(selectedValue?.toString(), option.value?.toString())

                    if (isOptionSelected) {
                      acc.push(option)
                    } else {
                      const foundedSubOption = option.options?.find(
                        (subOption) =>
                          subOption.value.toString() ===
                          (value.value?.toString() || value.toString()),
                      )

                      if (foundedSubOption) {
                        acc.push(foundedSubOption)
                      }
                    }
                    return acc
                  }, [])
                  return (
                    <div
                      className="text-ellipsis overflow-hidden"
                      testData={`dropdown-${testData}`}>
                      {renderMultiValues(selectedOptions)}
                    </div>
                  )
                }
              : () =>
                  (
                    <Placeholder errorMessage={errorMessage} testData={testData}>
                      {placeholder}
                    </Placeholder>
                  ) || ''
          }
          value={selectedValue}
          displayEmpty>
          {isMultipleSelect && withSelectAll && (
            <div tabIndex={null}>
              <Text
                className={classes.selectAll}
                onClick={() => {
                  let allValues = []

                  if (selectedValue.length !== optionsArr.length) {
                    allValues = options.map((option) => option.value)
                  }

                  onChange({ target: { value: allValues } })
                }}
                size={sizes.BASE}
                paragraph>
                {selectedValue.length !== optionsArr.length ? t('selectAll') : t('unselectAll')}
              </Text>
            </div>
          )}
          {optionsArr}
          {children && <MenuItem className="cursor-pointer">{children}</MenuItem>}
        </Select>
      </FormControl>
      {!!errorMessage && (
        <p className="mt-2 text-sm text-error" id={`${id}-help-text`}>
          {errorMessage}
        </p>
      )}
    </div>
  )
})

export default NDropdown

NDropdown.propTypes = {
  children: PT.node,
  className: PT.string,
  id: PT.oneOfType([PT.string, PT.number]).isRequired,
  isDisabled: PT.bool,
  errorMessage: PT.string,
  label: PT.string,
  name: PT.string.isRequired,
  onChange: PT.func.isRequired,
  options: PT.arrayOf(
    PT.shape({
      label: PT.oneOfType([PT.string, PT.func]),
      value: PT.string,
      testData: PT.string,
      typeLabel: PT.string,
      options: PT.arrayOf(
        PT.shape({
          label: PT.oneOfType([PT.string, PT.func]),
          value: PT.string,
          typeLabel: PT.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  placeholder: PT.string,
  renderValue: PT.func,
  value: PT.oneOfType([PT.string, PT.number]),
  withCheckmarks: PT.bool,
  isMultipleSelect: PT.bool,
  isHiddenInputValue: PT.bool,
  defaultValue: PT.string,
  type: PT.string,
  buttonClassName: PT.string,
  testData: PT.string,
  withSingleOptionAutoSelection: PT.bool,
  withSelectAll: PT.bool,
  hint: PT.string,
}
NDropdown.defaultProps = {
  children: null,
  className: '',
  isDisabled: false,
  errorMessage: undefined,
  label: '',
  placeholder: '',
  renderValue: null,
  value: null,
  withCheckmarks: false,
  isMultipleSelect: false,
  isHiddenInputValue: false,
  defaultValue: '',
  type: dropdownTypes.SELECT,
  buttonClassName: '',
  testData: '',
  withSingleOptionAutoSelection: false,
  withSelectAll: false,
}
