import PT from 'prop-types'
import React, { useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import {
  Button,
  Flex,
  Input,
  LoadingSpinner,
  NarrowList,
  Text,
  Tooltip,
} from '../../../../../ui-kit'
import {
  validateEmail,
  validatePhoneNumber,
  validateRequiredField,
} from '../../../../../utils/validators'
import { useTranslation } from 'react-i18next'
import NDropdown from '../../../../../ui-kit/components/dropdown/NDropdown'
import { COMPANY_ROLES_LIST } from '../../../../../constants/companyRolesList'
import { normalizePhone } from '../../../../../utils'
import { useBeforeUnload } from '../../../../../hooks/useBeforeUnload'
import AlertModal from '../../../../../ui-kit/components/alertModal/AlertModal'
import fontWeight from '../../../../../ui-kit/fontWeight'
import Toggle from '../../../../../ui-kit/components/inputs/Toggle'
import sizes from '../../../../../ui-kit/sizes'
import {
  GENERAL_CATEGORY,
  TRANSACTIONAL_CATEGORY,
} from '../../../../../components/notificationSettingsContent/customerNotificationsConfig'

const BuyerUserForm = ({
  isFormDirty,
  setDirtyFormState,
  closeForm,
  requestClose,
  initialValues,
  handleSubmitForm,
  loading,
  loadingNotificationData,
  submitButtonLabelKey,
  submitButtonTestData,
  cancelButtonTestData,
  isInactiveContract,
  isInvitationAccepted,
  isShowSendInvitation,
  membershipsContractReferenceIds,
}) => {
  const { t } = useTranslation()

  const [isNotificationTurnedOff, setIsNotificationTurnedOff] = useState(
    !initialValues?.sendInvitation,
  )

  const notificationColumns = useMemo(
    () => [
      {
        title: '',
        className: 'w-3/5',
      },
      {
        title: t('email'),
        className: 'w-1/5 text-black-500 font-normal',
      },
      {
        title: t('text'),
        className: 'w-1/5 text-black-500 font-normal',
      },
    ],
    [],
  )
  const notificationSettings = useMemo(() => {
    if (isInactiveContract) {
      return {
        [GENERAL_CATEGORY]: {
          ...initialValues.notificationSettings[GENERAL_CATEGORY],
          inactive: true,
        },
        [TRANSACTIONAL_CATEGORY]: initialValues.notificationSettings[TRANSACTIONAL_CATEGORY] ?? {},
      }
    }
    return initialValues.notificationSettings
  }, [isInactiveContract, initialValues])

  const notificationItems = useMemo(
    () =>
      Object.keys(notificationSettings).map((key) => (
        <>
          {notificationSettings[key].inactive && (
            <Flex
              alignItems="center"
              className="w-full h-40 p-5 border border-dashed my-2"
              key={key}>
              <Text align="center" color="text-black-500" size={sizes.XS}>
                {t('inactiveNotification')}
              </Text>
            </Flex>
          )}
          {!notificationSettings[key].inactive && (
            <Flex className="w-full p-3" key={key}>
              <Flex className="w-3/5">
                <Text>
                  {notificationSettings[key].contactTitle
                    ? t(notificationSettings[key].contactTitle)
                    : t(notificationSettings[key].title)}
                </Text>
              </Flex>
              <Flex className="w-1/5">
                <Field name={`notificationSettings.${key}.email`}>
                  {({ input }) => (
                    <Tooltip
                      content={
                        !notificationSettings[key].isEmailEditEnabled &&
                        t('communicationSettingUnavailable')
                      }>
                      <Toggle
                        disabled={
                          loading ||
                          !notificationSettings[key].isEmailEditEnabled ||
                          isNotificationTurnedOff
                        }
                        handleChange={input.onChange}
                        id={input.name}
                        name={input.name}
                        onChange={input.onChange}
                        value={isNotificationTurnedOff ? false : input.value}
                      />
                    </Tooltip>
                  )}
                </Field>
              </Flex>
              <Flex className="w-1/5">
                <Field name={`notificationSettings.${key}.text`}>
                  {({ input }) => (
                    <Tooltip
                      content={
                        !notificationSettings[key].isTextEditEnabled &&
                        t('communicationSettingUnavailable')
                      }>
                      <Toggle
                        disabled={
                          loading ||
                          !notificationSettings[key].isTextEditEnabled ||
                          isNotificationTurnedOff
                        }
                        handleChange={input.onChange}
                        id={input.name}
                        name={input.name}
                        onChange={input.onChange}
                        value={isNotificationTurnedOff ? false : input.value}
                      />
                    </Tooltip>
                  )}
                </Field>
              </Flex>
            </Flex>
          )}
        </>
      )),
    [initialValues, isNotificationTurnedOff],
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, dirty, submitError }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col mt-6 py-0 px-2 w-248" onSubmit={handleSubmit}>
            {(loadingNotificationData || loading) && <LoadingSpinner className="w-full" loading />}
            {!(loadingNotificationData || loading) && (
              <>
                <Flex>
                  <Flex className="w-1/2" column>
                    <div className="w-full">
                      <Field name="email">
                        {({ input, meta }) => {
                          return (
                            <Input
                              errorMessage={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              label={t('email')}
                              placeholder={t('email')}
                              tabIndex="1"
                              type="email"
                              disabled
                              {...input}
                            />
                          )
                        }}
                      </Field>
                    </div>
                    <div className="w-full mr-4 mt-6 flex flex-row">
                      <div className="w-6/12">
                        <Field name="firstName">
                          {({ input, meta }) => {
                            return (
                              <Input
                                disabled={isInvitationAccepted || isInactiveContract}
                                errorMessage={
                                  meta.error && meta.touched && meta.submitFailed
                                    ? meta.error
                                    : undefined
                                }
                                id={input.name}
                                label={t('firstName')}
                                placeholder={t('firstName')}
                                tabIndex="1"
                                {...input}
                              />
                            )
                          }}
                        </Field>
                      </div>

                      <div className="ml-4 w-6/12">
                        <Field name="lastName">
                          {({ input, meta }) => {
                            return (
                              <Input
                                disabled={isInvitationAccepted || isInactiveContract}
                                errorMessage={
                                  meta.error && meta.touched && meta.submitFailed
                                    ? meta.error
                                    : undefined
                                }
                                id={input.name}
                                label={t('lastName')}
                                placeholder={t('lastName')}
                                tabIndex="1"
                                {...input}
                              />
                            )
                          }}
                        </Field>
                      </div>
                    </div>

                    <Flex className="w-full mt-6">
                      <div className="w-4/12 mr-4">
                        <Field name="phoneNumber" parse={normalizePhone}>
                          {({ input, meta }) => {
                            return (
                              <Input
                                disabled={isInvitationAccepted || isInactiveContract}
                                errorMessage={
                                  meta.error && meta.touched && meta.submitFailed
                                    ? meta.error
                                    : undefined
                                }
                                id={input.name}
                                label={t('mobilePhone')}
                                placeholder={t('phone')}
                                tabIndex="1"
                                {...input}
                              />
                            )
                          }}
                        </Field>
                      </div>
                      <div className="w-4/12 mr-4">
                        <Field name="officePhoneNumber" parse={normalizePhone}>
                          {({ input, meta }) => {
                            return (
                              <Input
                                disabled={isInvitationAccepted || isInactiveContract}
                                errorMessage={
                                  meta.error && meta.touched && meta.submitFailed
                                    ? meta.error
                                    : undefined
                                }
                                id={input.name}
                                label={t('officePhone')}
                                placeholder={t('phone')}
                                tabIndex="1"
                                {...input}
                              />
                            )
                          }}
                        </Field>
                      </div>
                      <div className="w-3/12">
                        <Field name="extension">
                          {({ input, meta }) => {
                            return (
                              <Input
                                disabled={isInvitationAccepted || isInactiveContract}
                                errorMessage={
                                  meta.error && meta.touched && meta.submitFailed
                                    ? meta.error
                                    : undefined
                                }
                                id={input.name}
                                label={t('extension')}
                                placeholder={t('extension')}
                                tabIndex="1"
                                {...input}
                              />
                            )
                          }}
                        </Field>
                      </div>
                    </Flex>

                    <div className="w-full mt-6">
                      <Field name="membershipRole">
                        {({ input, meta }) => {
                          return (
                            <NDropdown
                              errorMessage={
                                meta.error && meta.submitFailed ? meta.error : undefined
                              }
                              id={input.name}
                              inputClassName="w-full"
                              isDisabled={isInactiveContract}
                              label={t('role')}
                              listClass="max-h-44 p-1 pt-0 pb-0"
                              name={input.name}
                              onChange={(e) => {
                                input.onChange(e.target.value)
                              }}
                              options={COMPANY_ROLES_LIST}
                              placeholder={t('role')}
                              value={input.value}
                            />
                          )
                        }}
                      </Field>
                    </div>
                    {!isInactiveContract && isInvitationAccepted && (
                      <>
                        <div className="w-full mt-4">
                          <Text color="text-orange-600">{t('contactInformationWarning')}</Text>
                        </div>
                      </>
                    )}
                    {!isInactiveContract && membershipsContractReferenceIds?.length && (
                      <>
                        <div className={`w-full ${isInvitationAccepted ? 'mt-1' : 'mt-4'}`}>
                          <Text color="text-orange-600">{t('listExistingMemberships')}</Text>
                        </div>
                        <div className="w-full">
                          {membershipsContractReferenceIds.map((id, count) => (
                            <Text color="text-orange-600" key={id}>
                              {id}
                              {count !== membershipsContractReferenceIds?.length - 1 && ', '}
                            </Text>
                          ))}
                        </div>
                      </>
                    )}
                  </Flex>
                  <div className="mx-11 border-1/2 border-warmBlack-400" />
                  <Flex className="w-1/2 my-8" column>
                    <>
                      <Text fontWeight={fontWeight.BOLD}>{t('communicationRules')}</Text>
                      <Text color="text-black-500 my-2" size={sizes.XS}>
                        {t('communicationRulesWarning')}
                      </Text>
                      <NarrowList
                        columns={notificationColumns}
                        columnsWrapperClassName="!py-0"
                        listItems={notificationItems}
                        paddingsClasses="p-0"
                        isColumnsBorderHidden
                        withColumns
                      />
                    </>
                  </Flex>
                </Flex>
                <Flex>
                  {isShowSendInvitation && (
                    <div className="mt-6">
                      <Tooltip
                        content={isInactiveContract ? t('customerUsersCannotInvited') : ''}
                        placement="bottom">
                        <Field name="sendInvitation">
                          {({ input }) => (
                            <Toggle
                              disabled={isInvitationAccepted || isInactiveContract}
                              handleChange={(value, name) => {
                                setIsNotificationTurnedOff(!value)
                                input.onChange(value, name)
                              }}
                              id={input.name}
                              name={input.name}
                              onChange={input.onChange}
                              value={input.value}
                            />
                          )}
                        </Field>
                        <Text className="ml-4" fontWeight={fontWeight.MEDIUM}>
                          {t('sendCustomerInvitation')}
                        </Text>
                      </Tooltip>
                    </div>
                  )}

                  {submitError && <span className="error leading-8">submitError</span>}
                </Flex>
              </>
            )}

            <div className="w-full mt-6 flex flex-row justify-end">
              <Button
                className="mr-2"
                disabled={loading}
                label={t('cancel')}
                onClick={requestClose}
                testData={cancelButtonTestData}
                variant="tertiary"
              />
              <Button
                label={t(submitButtonLabelKey)}
                testData={submitButtonTestData}
                type="submit"
              />
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => ({
        firstName: validateRequiredField(values.firstName),
        lastName: validateRequiredField(values.lastName),
        email: validateRequiredField(values.email) || validateEmail(values.email),
        phoneNumber: validatePhoneNumber(values.phoneNumber),
        membershipRole: validateRequiredField(values.membershipRole),
        officePhoneNumber: values.officePhoneNumber
          ? validatePhoneNumber(values.officePhoneNumber)
          : undefined,
      })}
    />
  )
}

BuyerUserForm.propTypes = {
  loading: PT.bool.isRequired,
  loadingNotificationData: PT.bool.isRequired,
  closeForm: PT.func.isRequired,
  isInvitationAccepted: PT.bool,
  isFormDirty: PT.bool.isRequired,
  requestClose: PT.func.isRequired,
  initialValues: PT.object.isRequired,
  handleSubmitForm: PT.func.isRequired,
  setDirtyFormState: PT.func.isRequired,
  submitButtonLabelKey: PT.string.isRequired,
  submitButtonTestData: PT.string,
  cancelButtonTestData: PT.string,
  isInactiveContract: PT.bool,
  isShowSendInvitation: PT.bool,
  membershipsContractReferenceIds: PT.array,
}

export default BuyerUserForm
