import React from 'react'
import { Button, Flex } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import sizes from '../../../ui-kit/sizes'

const ContractsSelected = ({
  models,
  onResendInvitationAction,
  onAssignRepAction,
  showPanel,
  handleClearAll,
  handleSelectAll,
  totalCountFilteredData,
  selectedContractLength,
  isSelectAllSelected,
}) => {
  const { t } = useTranslation()

  if (showPanel) {
    return (
      <Flex
        alignItems="center"
        className="w-full h-16 bg-white p-8 text-sm fixed bottom-0 right-0 lg:pl-56 md:pl-14"
        justifyContent="between"
        testData={'selected-invoices-panel'}
        row>
        <div className="pl-8">
          <span className="bg-primary primary px-2 py-1  rounded-md">{selectedContractLength}</span>
          <span className="pl-2">{t('selected')}</span>
          <span>
            {handleSelectAll && (
              <Button
                className="px-2 ml-4 w-25"
                disabled={!models.length || isSelectAllSelected}
                label={`${t('selectAll')} ${
                  totalCountFilteredData ? `(${totalCountFilteredData})` : ''
                }`}
                onClick={handleSelectAll}
                size={sizes.SM}
                testData="select-all-action"
                variant={buttonsVariants.SECONDARY}
              />
            )}
            <Button
              className="px-2 ml-4 w-25"
              disabled={!models.length}
              label={t('clearAll')}
              onClick={handleClearAll}
              size={sizes.SM}
              testData="clear-all-action"
              variant={buttonsVariants.SECONDARY}
            />
          </span>
        </div>

        <div>
          <Button
            className="px-6 mr-2 w-40"
            label={t('sendInvitations')}
            onClick={onResendInvitationAction}
            size={sizes.SM}
            testData="resend-invitation-action"
            variant={buttonsVariants.PRIMARY}
          />
          <Button
            className="px-6 mr-2 w-40"
            label={t('assignReps')}
            onClick={onAssignRepAction}
            size={sizes.SM}
            testData="assigned-reps-action"
            variant={buttonsVariants.SECONDARY}
          />
        </div>
      </Flex>
    )
  }

  return null
}

ContractsSelected.propTypes = {
  models: PT.arrayOf(
    PT.shape({
      outstandingAmountCents: PT.number,
    }),
  ),
  handleClearAll: PT.func,
  onAssignRepAction: PT.func,
  onResendInvitationAction: PT.func,
  showPanel: PT.bool.isRequired,
  handleSelectAll: PT.func,
  handleSelectAllPayable: PT.func,
  totalCountFilteredData: PT.number,
  selectedContractLength: PT.number,
  isSelectAllSelected: PT.bool,
}
ContractsSelected.defaultProps = {
  models: [],
}

export default ContractsSelected
