import React from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '../../../components/headers/PageHeader'
import { Text } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import LienAlert from './LienAlert'

const LienAlertPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeader className="mb-0" headerTitle={t('lienAlert')} />
      <Text className="pb-6" size={sizes.BASE} paragraph>
        {t('lienAlertDescription')}
      </Text>
      <LienAlert />
    </>
  )
}

export default LienAlertPage
