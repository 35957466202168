import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryParams } from '../../hooks/useQueryParams'
import { camelCase, toInteger } from 'lodash'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import {
  ContractsQuery,
  ContractsAvailableFilters,
  ActiveContractsCountQuery,
} from '../../queries/contracts.gql'
import { VendorQuery } from '../../queries/vendors.gql'

import PageHeader from '../../components/headers/PageHeader'
import { MONEY } from '../../utils/dataTypes'
import StatsFilters from '../../components/StatsFilters'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { useNavigate } from 'react-router-dom'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import { ConfirmationDialog, Flex, Money, StatusBadge, Text, Tooltip } from '../../ui-kit'
import { getModelsByIds, getPaginationData, parseFilterQuery } from '../../utils/utils'
import { getDisplayStatusValue, getStatisticData } from './utils'
import FiltersControlButton from '../../components/filters/FiltersControlButton'
import { applyStatusFilter } from '../../components/filters/filterUtils'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import ContractsSelected from './contractActions/ContractsSelected'
import AddNewAssignedRepsModal from './customerInfo/contactInfoSections/AddNewAssignedRepsModal'
import colors from '../../ui-kit/colors'
import cx from 'classnames'
import { contractPortalFieldMapping } from './contractActions/utils'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { SendContractsInvitationsMutation } from '../../queries/mutations/sendContractsInvitations.gql'
import { AssignContractsRepMutation } from '../../queries/mutations/assignedRep.gql'
import { useNotifications } from '../../hooks/useNotifications'
import ContractIcons from './contractActions/ContractIcons'
import SavedTableViewControl from '../../components/savedTableViews/SavedTableViewControl'

const Contracts = () => {
  const { queryParams } = useQueryParams()
  const [selectedContracts, setSelectedContracts] = useState([])
  const [currentSelectionModel, setCurrentSelectionModel] = useState([])
  const [previousSelectionModel, setPreviousSelectionModel] = useState([])
  const [assignedRepsModalOpened, setAssignedRepsModalOpened] = useState(false)
  const [contracts, setContracts] = useState([])
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false)
  const [confirmPortalInvitationsModalOpened, setConfirmPortalInvitationsModalOpened] =
    useState(false)
  const [totalCountFilteredActive, setTotalCountFilteredActive] = useState(0)

  const defaultSort = 'outstanding_amount_cents.desc'
  const sort = queryParams.sort || defaultSort
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const [availableFilters, setAvailableFilters] = useState(null)
  const filtersQuery = useMemo(() => queryParams.filters || null, [queryParams.filters])
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )
  const entity = 'contracts'
  const [filterValidationCompleted, setFilterValidationCompleted] = useState(null)

  const { newNotification } = useNotifications()

  useEffect(() => {
    setBreadcrumbs([{ label: t('customers'), link: '/customers' }, { label: t('allCustomers') }])

    return function cleanup() {
      setAvailableFilters(null)
    }
  }, [])

  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(
    () => ({
      filters: userFilters,
      sort,
      page,
      search,
      perPage: pageSize,
    }),
    [userFilters, sort, page, search, pageSize],
  )
  const queryVariablesActive = useMemo(
    () => ({
      filters: userFilters ? [...userFilters, { key: 'status', values: ['active'] }] : [],
      sort,
      page,
      search,
      perPage: pageSize,
    }),
    [userFilters, sort, page, search, pageSize],
  )
  const divideSelection = (rows, model) => {
    const previousSelection = []
    const currentSelection = []
    const selectionModel = model || previousSelectionModel
    selectionModel.forEach((contract) => {
      const index = rows.findIndex((item) => contract.id === item.id)
      if (index > -1) {
        currentSelection.push(contract)
      } else {
        previousSelection.push(contract)
      }
    })
    setPreviousSelectionModel(previousSelection)
    setCurrentSelectionModel(currentSelection)
  }

  const onCompletedRecords = useCallback(
    (response) => {
      setContracts(response.contracts)
      if (isSelectAllSelected) {
        setCurrentSelectionModel(
          response.contracts?.data?.filter((row) => row.status !== 'inactive'),
        )
      } else {
        divideSelection(response.contracts?.data || [])
      }
    },
    [previousSelectionModel],
  )

  const onCompletedActiveContractsCount = useCallback((response) => {
    setTotalCountFilteredActive(response.contracts?.paginationData?.totalCount)
  }, [])

  const rows = contracts?.data

  const { loading } = useCustomQuery({
    query: ContractsQuery,
    onCompleted: onCompletedRecords,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'ContractsQuery', target: 'Contracts' },
  })

  useCustomQuery({
    query: ActiveContractsCountQuery,
    onCompleted: onCompletedActiveContractsCount,
    queryOptions: {
      variables: queryVariablesActive,
    },
    rollbarOptions: { operationName: 'ContractsQuery', target: 'Contracts' },
  })
  const setSelectionModel = (ids) => {
    const selected = getModelsByIds(rows, ids)
    setCurrentSelectionModel(selected)
    if (isSelectAllSelected) {
      setIsSelectAllSelected(false)
      setSelectedContracts(selected)
    } else {
      setSelectedContracts(previousSelectionModel.concat(selected))
    }
  }

  const handleSelectionModelChange = useCallback(
    (models) => {
      setSelectionModel(models)
    },
    [rows, previousSelectionModel],
  )

  const handleClearAll = () => {
    setSelectedContracts([])
    setPreviousSelectionModel([])
    setCurrentSelectionModel([])
    setIsSelectAllSelected(false)
  }

  const handleSelectAll = () => {
    setPreviousSelectionModel([])
    const filteredRow = rows.filter((row) => row.status !== 'inactive')
    setCurrentSelectionModel(filteredRow)
    setIsSelectAllSelected(true)
  }

  useEffect(() => {
    setPreviousSelectionModel(selectedContracts)
  }, [sort, page, search, filtersQuery])

  const onCompleted = useCallback((response) => {
    response?.contracts?.availableFilters &&
      setAvailableFilters(response.contracts.availableFilters)
  }, [])

  useCustomQuery({
    query: ContractsAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'ContractsAvailableFilters', target: 'Contracts' },
  })

  const vendorData = useCustomQuery({
    query: VendorQuery,
    rollbarOptions: { operationName: 'VendorQuery', target: 'Contracts' },
  })

  const { overdueLevelGroups, outstandingAmountCents, paymentSettings, vendorPreference } =
    vendorData?.data?.currentVendor || {}

  const statisticData = [
    ...getStatisticData(overdueLevelGroups, t),
    {
      id: 'totalOutstanding',
      title: t('totalOutstanding'),
      value: outstandingAmountCents || 0,
      type: MONEY,
      level: null,
      notClickable: true,
    },
  ]

  const navigate = useNavigate()

  const paginationData = getPaginationData(contracts)

  const getWrappedTextValue = (status, value) => {
    return (
      <Tooltip content={status === 'inactive' ? t('inactiveCustomer') : ''} placement="bottom">
        <Text className={status === 'inactive' ? 'opacity-70' : ''} color={colors.GRAY_500}>
          {value}
        </Text>
      </Tooltip>
    )
  }
  const getWrappedMoneyValue = (status, value) => {
    return (
      <Tooltip content={status === 'inactive' ? t('inactiveCustomer') : ''} placement="bottom">
        <Money className={status === 'inactive' ? 'opacity-70' : ''} value={value} />
      </Tooltip>
    )
  }

  const columns = useMemo(() => {
    const columnsFirstPart = [
      {
        field: 'id',
        filterId: additionalFilters.includes('id') && 'id',
        headerName: t('id'),
        hide: true,
      },
      {
        field: 'status',
        filterId: 'status',
        filterTitle: t('byActiveInactive'),
        hide: true,
      },
      {
        field: 'referenceId',
        headerName: t('id'),
        renderCell: (values) => getWrappedTextValue(values?.row?.status, values?.row?.referenceId),
        flex: 12.5,
      },
      {
        field: 'homeLocation',
        filterId: 'homeLocation',
        filterTitle: t('homeLocation'),
        headerName: t('homeLocation'),
        renderCell: (values) =>
          getWrappedTextValue(values?.row?.status, values?.row?.location?.name),
      },
      {
        field: 'name',
        headerName: t('customerName'),
        flex: 20,
        renderCell: (values) => getWrappedTextValue(values?.row?.status, values?.row?.buyer?.name),
      },
      {
        field: 'outstandingAmountCents',
        filterId: 'outstandingAmount',
        filterDataType: MONEY,
        headerName: t('outstanding'),
        flex: 12.5,
        renderCell: (values) => (
          <Tooltip
            content={values?.row?.status === 'inactive' ? t('inactiveCustomer') : ''}
            placement="bottom">
            <Money
              className={values?.row?.status === 'inactive' ? 'opacity-70' : ''}
              value={values?.row?.outstandingAmountCents}
            />{' '}
          </Tooltip>
        ),
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'netDueAmountCents',
        filterDataType: MONEY,
        headerName: t('netDue'),
        flex: 12.5,
        renderCell: (values) =>
          getWrappedMoneyValue(values?.row?.status, values?.row?.netDueAmountCents),
        align: 'right',
        headerAlign: 'right',
      },
    ]
    const columnsSecondPart = [
      {
        field: 'pendingPaymentsAmountCents',
        headerName: t('pendingPayments'),
        flex: 12.5,
        renderCell: (values) =>
          getWrappedMoneyValue(values?.row?.status, values?.row?.pendingPaymentsAmountCents),
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        field: 'lastPaymentDate',
        filterId: 'lastPaymentDate',
        headerName: t('lastPayment'),
        align: 'center',
        headerAlign: 'center',
        flex: 12.5,
        renderCell: (values) => (
          <Tooltip
            content={values?.row?.status === 'inactive' ? t('inactiveCustomer') : ''}
            placement="bottom">
            <Text
              className={values?.row?.status === 'inactive' ? 'opacity-70' : ''}
              color={colors.GRAY_500}>
              <FormattedDate date={values?.row?.lastPaymentDate} />
            </Text>
          </Tooltip>
        ),
      },
      {
        field: 'autopay',
        headerName: '',
        filterId: 'autopay',
        filterTitle: t('autopay'),
        width: 55,
        sortable: false,
        renderCell: (values) => <ContractIcons model={values?.row} />,
      },
      {
        field: 'overdueLevel',
        filterId: 'overdueLevel',
        headerName: t('status'),
        flex: 12,
        renderCell: (values) => {
          return (
            <Tooltip
              content={values?.row?.status === 'inactive' ? t('inactiveCustomer') : ''}
              placement="bottom">
              <StatusBadge
                iconName="dot"
                value={getDisplayStatusValue(values?.row?.overdueLevel)}
              />
            </Tooltip>
          )
        },
      },
      {
        field: 'customerPortalStatus',
        filterId: 'customerPortalStatus',
        headerName: t('portal'),
        flex: 12,
        renderCell: (values) =>
          getWrappedTextValue(
            values?.row?.status,
            contractPortalFieldMapping[values?.row?.buyer?.buyerUsersStatus]?.label,
          ),
      },
      {
        id: 'vendorUser',
        filterId: 'vendorUser',
        headerName: t('assignedRep'),
        hide: true,
      },
    ]
    const creditLimitColumn = {
      field: 'creditLimitCents',
      headerName: t('creditLimit'),
      flex: 12.5,
      renderCell: (values) =>
        getWrappedMoneyValue(values?.row?.status, values?.row?.creditLimitCents),
      align: 'right',
      headerAlign: 'right',
    }

    return vendorPreference?.showCreditLimit
      ? [...columnsFirstPart, creditLimitColumn, ...columnsSecondPart]
      : [...columnsFirstPart, ...columnsSecondPart]
  }, [t, paymentSettings, additionalFilters, vendorPreference])

  const handleRowClick = (model) => {
    navigate(`/customers/${model?.row?.id}/overview`)
  }

  const defaultSortModel = useMemo(() => {
    const sortSource = queryParams?.sort || defaultSort

    const [field, direction] = sortSource.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [queryParams?.sort])
  const onItemClick = useCallback(
    ({ level }) => {
      const levelTitle = getDisplayStatusValue(level?.overdueLevel)
      applyStatusFilter('overdue_level', levelTitle, availableFilters, navigate, '/customers')
    },
    [availableFilters, navigate],
  )
  const [assignContractsRep, { loading: assignContractsRepInProgress }] = useCustomMutation({
    mutation: AssignContractsRepMutation,
    rollbarOptions: {
      operationName: 'AssignContractsRepMutation',
      target: 'AssignContractsRepMutation',
    },
  })

  const [sendContractsInvitations, { loading: sendContractsInvitationsInProgress }] =
    useCustomMutation({
      mutation: SendContractsInvitationsMutation,
      rollbarOptions: {
        operationName: 'SendContractsInvitationsMutation',
        target: 'SendContractsInvitations',
      },
      mutationOptions: {
        refetchQueries: [ContractsQuery],
      },
    })

  const handleSendInvitation = () => {
    const contractIds = isSelectAllSelected
      ? undefined
      : selectedContracts.map((contract) => contract.id)
    const filters = isSelectAllSelected
      ? userFilters
        ? [...userFilters, { key: 'status', values: ['active'] }]
        : [{ key: 'status', values: ['active'] }]
      : undefined
    sendContractsInvitations({
      variables: { data: { contractIds, filters } },
    }).then(({ data }) => {
      const responseData = data?.sendContractsInvitations || {}
      if (responseData?.entity) {
        newNotification({ success: t('customerPortalInvitationsSent') })
      }
      setConfirmPortalInvitationsModalOpened(false)
      handleClearAll()
    })
  }

  const handleAssignedRepFormSubmit = (values) => {
    const contractIds = isSelectAllSelected ? [] : selectedContracts.map((contract) => contract.id)
    const filters = isSelectAllSelected
      ? userFilters
        ? [...userFilters, { key: 'status', values: ['active'] }]
        : [{ key: 'status', values: ['active'] }]
      : []
    assignContractsRep({
      variables: { data: { contractIds, filters, ...values } },
    }).then(({ data }) => {
      const responseData = data?.assignContractsRep || {}
      if (responseData?.entity) {
        newNotification({ success: t('repsAssigned') })
      }
      setAssignedRepsModalOpened(false)
      handleClearAll()
    })
  }

  return (
    <>
      <StatsFilters data={statisticData} onItemClick={onItemClick} />

      <Flex className="justify-between items-center">
        <PageHeader headerTitle={t('customers')} subHeading={paginationData.totalCount} />
        <Flex>
          <SavedTableViewControl
            availableFilters={availableFilters}
            contractId={null}
            entity={entity}
            setFilterValidationCompleted={setFilterValidationCompleted}
          />
          <FiltersControlButton />
        </Flex>
      </Flex>
      <div className={cx('flex mt-4', !!selectedContracts.length && 'pb-12')}>
        <DataGridComponent
          availableFilters={availableFilters}
          columns={columns}
          handleSelectionModelChange={handleSelectionModelChange}
          isRowSelectable={(params) => params.row.status !== 'inactive'}
          loading={loading || vendorData?.loading || !filterValidationCompleted}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rowClassName="cursor-pointer"
          rowClick={handleRowClick}
          rows={rows}
          searchLabel={t('customers')}
          selectionModel={currentSelectionModel.map((contract) => contract.id)}
          sortModel={defaultSortModel}
          checkboxSelection
        />
      </div>

      <ContractsSelected
        handleClearAll={handleClearAll}
        handleSelectAll={handleSelectAll}
        iconType="solid"
        isSelectAllSelected={isSelectAllSelected}
        models={selectedContracts}
        onAssignRepAction={() => setAssignedRepsModalOpened(true)}
        onResendInvitationAction={() => setConfirmPortalInvitationsModalOpened(true)}
        selectedContractLength={
          isSelectAllSelected ? totalCountFilteredActive : selectedContracts.length
        }
        showPanel={!!selectedContracts.length}
        totalCountFilteredData={totalCountFilteredActive}
      />
      {assignedRepsModalOpened && (
        <AddNewAssignedRepsModal
          assignContractsRepInProgress={assignContractsRepInProgress}
          assignedReps={[]}
          handleFormSubmit={handleAssignedRepFormSubmit}
          isOpened={assignedRepsModalOpened}
          setIsOpened={setAssignedRepsModalOpened}
          isMultipleContracts
        />
      )}
      <ConfirmationDialog
        confirmationMessage={t('confirmPortalInvitations')}
        isOpened={confirmPortalInvitationsModalOpened}
        isSubmitButtonYesDisabled={sendContractsInvitationsInProgress}
        onModalClose={() => setConfirmPortalInvitationsModalOpened(false)}
        onSubmitButtonNoClick={() => setConfirmPortalInvitationsModalOpened(false)}
        onSubmitButtonYesClick={() => handleSendInvitation()}
        setIsOpened={setConfirmPortalInvitationsModalOpened}
        title={t('confirmInvitations')}
      />
    </>
  )
}

export default Contracts
