import React, { useMemo } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Dialog, Flex, Money, Text, Tooltip } from '../../../ui-kit'
import { getFormattedDate } from '../../../ui-kit/utils/dateUtils'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import Button from '../../../ui-kit/components/buttons/Button'
import { useNavigate } from 'react-router-dom'
import { recordTypes } from '../../invoices/invoicesUtils'
import { fcIcon } from '../../../ui-kit/assets'
import { downloadFile } from '../../reports/logic/reportsUtils'
import { useSelector } from 'react-redux'
import { selectAuthToken } from '../../../store/selectors/auth'
import { useRollbar } from '@rollbar/react'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'

const getPaymentDetailsXlsxUrl = (contractId, payableId) =>
  `/contracts/${contractId}/payments/${payableId}/details_report.xlsx`
const PaymentInvoicesDetailsModal = ({
  isOpened,
  payableId,
  setIsOpened,
  transactionId,
  invoices,
  paymentDate,
  paymentMethod,
  contractId,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const authToken = useSelector(selectAuthToken)
  const rollbar = useRollbar()

  const handleInvoiceNumberClick = (invoice) => {
    const invoiceType = invoice.outstandingAmountCents > 0 ? 'outstanding' : 'paid'
    navigate(
      // eslint-disable-next-line max-len
      `/customers/${contractId}/invoices/${invoiceType}?additionalFilters=invoicedNumber&filters=invoicedNumber.${invoice.invoiceNumber}&openSidebarParams=invoiceNumber.${invoice.invoiceNumber}`,
    )
  }
  const columns = useMemo(
    () => [
      {
        field: 'issueDate',
        headerName: t('invoiceDate'),
        renderCell: (values) =>
          values?.row?.isTotal ? (
            <Text color="text-warmBlack-500" fontWeight={fontWeight.SEMIBOLD}>
              {t('total')}
            </Text>
          ) : (
            <FormattedDate date={values?.row?.issueDate} />
          ),
        flex: 10,
        sortable: false,
      },
      {
        field: 'invoiceNumber',
        headerName: t('number'),
        renderCell: (values) => (
          <Button
            label={values?.row?.invoiceNumber}
            onClick={() => handleInvoiceNumberClick(values?.row)}
            size={sizes.SM}
            testData="payment-invoice-details-invoice-number-button"
            variant={buttonsVariants.LINK}
          />
        ),
        flex: 10,
        sortable: false,
      },
      {
        field: 'fcIcon',
        headerName: '',
        sortable: false,
        renderCell: (values) =>
          values.row.recordType === recordTypes.FINANCE_CHARGE && (
            <Tooltip content={t('financeCharges')}>
              <img alt="Suppli" className="h-5" src={fcIcon} />
            </Tooltip>
          ),
        width: 40,
      },
      {
        field: 'projectName',
        headerName: t('project'),
        renderCell: (values) => values?.row?.project,
        flex: 15,
        sortable: false,
      },
      {
        field: 'coveredOutstandingAmountCents',
        headerName: t('osPaid'),
        renderCell: (values) => (
          <Money
            className={values?.row?.isTotal && 'font-semibold'}
            value={values?.row?.coveredOutstandingAmountCents}
          />
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 10,
        sortable: false,
      },
      {
        field: 'appliedDiscountAmountCents',
        headerName: t('discApplied'),
        renderCell: (values) => (
          <Money
            className={values?.row?.isTotal && 'font-semibold'}
            value={values?.row?.appliedDiscountAmountCents}
          />
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 10,
        sortable: false,
      },
      {
        field: 'creditsApplied',
        headerName: t('creditsApplied'),
        renderCell: (values) => (
          <Money
            className={values?.row?.isTotal && 'font-semibold'}
            value={values?.row?.creditsApplied}
          />
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 10,
        sortable: false,
      },
      {
        field: 'depositsApplied',
        headerName: t('depositsApplied'),
        renderCell: (values) => (
          <Money
            className={values?.row?.isTotal && 'font-semibold'}
            value={values?.row?.depositsApplied}
          />
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 10,
        sortable: false,
      },
      {
        field: 'netPayment',
        headerName: t('netPayment'),
        renderCell: (values) => (
          <Money
            className={values?.row?.isTotal && 'font-semibold'}
            value={values?.row?.netPayment}
          />
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 10,
        sortable: false,
      },
    ],
    [t],
  )
  const rows = useMemo(() => {
    const {
      invoicesRows,
      totalNetPayment,
      totalCreditsApplied,
      totalDepositsApplied,
      totalAppliedPenaltyAmountCents,
      totalAppliedDiscountAmountCents,
      totalCoveredOutstandingAmountCents,
    } = invoices.reduce(
      (acc, invoice) => {
        const validAssignment = invoice.payableInvoiceAssignments.find(
          (invoiceAssignment) => invoiceAssignment.payableId === payableId,
        )

        if (!validAssignment) {
          return acc
        }

        const row = {
          id: invoice.id,
          issueDate: invoice.issueDate,
          recordType: invoice.recordType,
          project: invoice.project?.name || '-',
          invoiceNumber: invoice.invoiceNumber,
          outstandingAmountCents: invoice.outstandingAmountCents,
          netPayment: validAssignment.chargeAmountCents,
          appliedPenaltyAmountCents: validAssignment.appliedPenaltyAmountCents,
          appliedDiscountAmountCents: validAssignment.appliedDiscountAmountCents,
          coveredOutstandingAmountCents: validAssignment.coveredOutstandingAmountCents,
          creditsApplied:
            validAssignment.creditUsages?.reduce(
              (acc, credit) => acc + (credit?.amountCents || 0),
              0,
            ) || 0,
          depositsApplied:
            validAssignment.depositUsages?.reduce(
              (acc, deposit) => acc + (deposit?.amountCents || 0),
              0,
            ) || 0,
        }
        acc.invoicesRows.push(row)
        acc.totalNetPayment += row.netPayment
        acc.totalAppliedPenaltyAmountCents += row.appliedPenaltyAmountCents
        acc.totalAppliedDiscountAmountCents += row.appliedDiscountAmountCents
        acc.totalCoveredOutstandingAmountCents += row.coveredOutstandingAmountCents
        acc.totalCreditsApplied += row.creditsApplied
        acc.totalDepositsApplied += row.depositsApplied

        return acc
      },
      {
        invoicesRows: [],
        totalNetPayment: 0,
        totalCreditsApplied: 0,
        totalDepositsApplied: 0,
        totalAppliedPenaltyAmountCents: 0,
        totalAppliedDiscountAmountCents: 0,
        totalCoveredOutstandingAmountCents: 0,
      },
    )
    invoicesRows.push({
      id: 'totals',
      project: '',
      isTotal: true,
      outstandingAmountCents: '',
      netPayment: totalNetPayment,
      appliedPenaltyAmountCents: totalAppliedPenaltyAmountCents,
      appliedDiscountAmountCents: totalAppliedDiscountAmountCents,
      coveredOutstandingAmountCents: totalCoveredOutstandingAmountCents,
      creditsApplied: totalCreditsApplied,
      depositsApplied: totalDepositsApplied,
    })
    return invoicesRows
  }, [invoices, payableId])

  const handleDownload = () =>
    downloadFile(
      getPaymentDetailsXlsxUrl(contractId, payableId),
      authToken,
      {},
      `payment_details_${transactionId}.xlsx`,
      rollbar,
    )

  return (
    <Dialog isOpened={isOpened} setIsOpened={setIsOpened} title={t('paymentApplicationDetail')}>
      <Flex className="w-full w-322" column>
        <Flex className="pt-4" justifyContent="between">
          <Flex className="pt-2 whitespace-pre">
            <Text color="text-black-500">{t('id')}: </Text>
            <Text color="text-black-700" fontWeight={fontWeight.MEDIUM}>
              {transactionId}
            </Text>
            {' | '}
            <Text color="text-black-500">{t('paymentDate')}: </Text>
            <Text color="text-black-700" fontWeight={fontWeight.MEDIUM}>
              {getFormattedDate(paymentDate)}
            </Text>
            {' | '}
            <Text color="text-black-500">{t('paymentMethod')}: </Text>
            <Text color="text-black-700" fontWeight={fontWeight.MEDIUM}>
              {paymentMethod}
            </Text>
          </Flex>
          <Flex justifyContent="end">
            <Button
              className="ml-4"
              label={t('exportExcel')}
              onClick={handleDownload}
              size={sizes.SM}
              testData="export-xlsx"
              variant={buttonsVariants.PRIMARY}
            />
          </Flex>
        </Flex>
        <DataGridComponent
          columns={columns}
          pageSize={100}
          rows={rows}
          wrapperClassNames="mt-8"
          localPagination
        />
      </Flex>
    </Dialog>
  )
}

PaymentInvoicesDetailsModal.propTypes = {
  isOpened: PT.bool,
  invoices: PT.arrayOf(PT.any),
  payableId: PT.string,
  contractId: PT.string,
  setIsOpened: PT.func.isRequired,
  paymentDate: PT.string,
  transactionId: PT.string,
  paymentMethod: PT.string,
}

PaymentInvoicesDetailsModal.defaultProps = {
  isOpened: false,
  invoices: [],
  payableId: '',
  contractId: '',
  paymentDate: '',
  transactionId: '',
  paymentMethod: '',
}

export default PaymentInvoicesDetailsModal
