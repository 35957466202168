import { FORM_ERROR } from 'final-form'

export const handleAuthorizationError = (error, t) => {
  switch (error?.response?.status) {
    case 401:
    case 404:
      return { [FORM_ERROR]: t('invalidCreds') }
    case 422:
    case 423:
      return { [FORM_ERROR]: t('inactiveAccountError') }
    default:
      return { [FORM_ERROR]: error?.response?.data?.errors || t('unexpectedError') }
  }
}
