export const getStatesList = (t) => [
  {
    label: t('Alabama'),
    value: 'AL',
  },
  {
    label: t('Alaska'),
    value: 'AK',
  },
  {
    label: t('Arizona'),
    value: 'AZ',
  },
  {
    label: t('Arkansas'),
    value: 'AR',
  },
  {
    label: t('California'),
    value: 'CA',
  },
  {
    label: t('Colorado'),
    value: 'CO',
  },
  {
    label: t('Connecticut'),
    value: 'CT',
  },
  {
    label: t('Delaware'),
    value: 'DE',
  },
  {
    label: t('Florida'),
    value: 'FL',
  },
  {
    label: t('Georgia'),
    value: 'GA',
  },
  {
    label: t('Hawaii'),
    value: 'HI',
  },
  {
    label: t('Idaho'),
    value: 'ID',
  },
  {
    label: t('Illinois'),
    value: 'IL',
  },
  {
    label: t('Indiana'),
    value: 'IN',
  },
  {
    label: t('Iowa'),
    value: 'IA',
  },
  {
    label: t('Kansas'),
    value: 'KS',
  },
  {
    label: t('Kentucky'),
    value: 'KY',
  },
  {
    label: t('Louisiana'),
    value: 'LA',
  },
  {
    label: t('Maine'),
    value: 'ME',
  },
  {
    label: t('Maryland'),
    value: 'MD',
  },
  {
    label: t('Massachusetts'),
    value: 'MA',
  },
  {
    label: t('Michigan'),
    value: 'MI',
  },
  {
    label: t('Minnesota'),
    value: 'MN',
  },
  {
    label: t('Mississippi'),
    value: 'MS',
  },
  {
    label: t('Missouri'),
    value: 'MO',
  },
  {
    label: t('Montana'),
    value: 'MT',
  },
  {
    label: t('Nebraska'),
    value: 'NE',
  },
  {
    label: t('Nevada'),
    value: 'NV',
  },
  {
    label: t('New Hampshire'),
    value: 'NH',
  },
  {
    label: t('New Jersey'),
    value: 'NJ',
  },
  {
    label: t('New Mexico'),
    value: 'NM',
  },
  {
    label: t('New York'),
    value: 'NY',
  },
  {
    label: t('North Carolina'),
    value: 'NC',
  },
  {
    label: t('North Dakota'),
    value: 'ND',
  },
  {
    label: t('Ohio'),
    value: 'OH',
  },
  {
    label: t('Oklahoma'),
    value: 'OK',
  },
  {
    label: t('Oregon'),
    value: 'OR',
  },
  {
    label: t('Pennsylvania'),
    value: 'PA',
  },
  {
    label: t('Rhode Island'),
    value: 'RI',
  },
  {
    label: t('South Carolina'),
    value: 'SC',
  },
  {
    label: t('South Dakota'),
    value: 'SD',
  },
  {
    label: t('Tennessee'),
    value: 'TN',
  },
  {
    label: t('Texas'),
    value: 'TX',
  },
  {
    label: t('Utah'),
    value: 'UT',
  },
  {
    label: t('Vermont'),
    value: 'VT',
  },
  {
    label: t('Virginia'),
    value: 'VA',
  },
  {
    label: t('Washington'),
    value: 'WA',
  },
  {
    label: t('West Virginia'),
    value: 'WV',
  },
  {
    label: t('Wisconsin'),
    value: 'WI',
  },
  {
    label: t('Wyoming'),
    value: 'WY',
  },
]
