export const alertsAvailableFilters = [
  {
    key: 'id',
    type: 'value',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'recorded_date',
    type: 'dates_range',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'created_at',
    type: 'dates_range',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'contract_id',
    type: 'autocomplete_select',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'filing_state',
    type: 'select',
    options: [],
    __typename: 'Filter',
  },
]
