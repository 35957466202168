import { getQueryString } from './getQueryString'

export const applyFilters = ({ filters, setQueryParams, refs }) => {
  // const validFilters = validateFilters(filters)

  // TODO remove deprecated+unused refs logic
  if (refs) {
    Object.values(refs).forEach((ref) => ref?.current?.resetLocalPage())
  }

  setQueryParams?.({ filters: getQueryString(filters) }, ['page'])
}
