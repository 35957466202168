import React from 'react'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import Tooltip from '../../../ui-kit/components/tooltip/Tooltip'
import { Flex, Icon, Text } from '../../../ui-kit'
import colors from '../../../ui-kit/colors'
import sizes from '../../../ui-kit/sizes'

const ContractIcons = ({ model }) => {
  const { t } = useTranslation()
  const tooptipContent = (
    <Flex column>
      <div>
        <Text color="text-black-500" size={sizes.SM}>
          {t('inactiveCustomer')}
        </Text>
      </div>
      <div>
        <Text color="text-black-500" size={sizes.SM}>
          {t('autopayEnabled')}
        </Text>
      </div>
    </Flex>
  )
  const renderIcon = (contract) => {
    if (contract.autopayConfiguration && contract.status === 'inactive') {
      return (
        <Tooltip content={tooptipContent} placement="bottom">
          <div className="relative">
            <Icon color={colors.DARK_RED} name="xCircle" size={sizes.XL} type="solid" />
            <Icon
              className="absolute top-[-8px] right-[-10px]"
              color={colors.PRIMARY}
              name="plusCircle"
              size={sizes.XS}
              type="solid"
            />
          </div>
        </Tooltip>
      )
    }
    if (contract.autopayConfiguration) {
      return (
        <Tooltip content={t('autopay')} placement="bottom">
          {/* eslint-disable-next-line max-len */}
          <div className="pt-0.5 ml-0.5 border rounded-full w-[22px] h-[22px] border-primary-700 bg-primary-700 text-white text-center text-xs">
            A
          </div>
        </Tooltip>
      )
    }
    if (contract.status === 'inactive') {
      return (
        <Tooltip content={t('inactiveCustomer')} placement="bottom">
          <Icon color={colors.DARK_RED} name="xCircle" size={sizes.XL} type="solid" />
        </Tooltip>
      )
    }
  }

  return renderIcon(model)
}

ContractIcons.propTypes = {
  model: PT.shape({
    autopayConfiguration: PT.bool,
    status: PT.string,
  }).isRequired,
}

export default ContractIcons
