import React, { useCallback, useMemo } from 'react'
import PT from 'prop-types'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, Flex, Text } from '../../../../ui-kit'
import { useBeforeUnload } from '../../../../hooks/useBeforeUnload'
import AlertModal from '../../../../ui-kit/components/alertModal/AlertModal'
import fontWeight from '../../../../ui-kit/fontWeight'
import Toggle from '../../../../ui-kit/components/inputs/Toggle'
import { useNotifications } from '../../../../hooks/useNotifications'
import { useDirtyFormAlert } from '../../../../hooks/useDirtyFormAlert'
import CurrencyInput from '../../../../ui-kit/components/inputs/CurrencyInput'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import NDropdown from '../../../../ui-kit/components/dropdown/NDropdown'
import { FORM_ERROR } from 'final-form'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'
import { UpdateLienGeneralSettingsMutation } from '../../../../queriesUpdated/mutations/updateLienMonitorVendorSetting.gql'
import { LienMonitorVendorSettingQuery } from '../../../../queriesUpdated/queries/lienMonitorVendorSetting.gql'
import { useCurrentUser } from '../../../../hooks/useCurrentUser'
import { useCustomQuery } from '../../../../hooks/useCustomQuery'
import { VendorOverdueLevelGroups } from '../../../../queries/vendors.gql'
import { getDisplayStatusValue } from '../../../contracts/utils'
import sizes from '../../../../ui-kit/sizes'
import { LienMonitorContractSettingsQuery } from '../../../../queriesUpdated/queries/lienMonitorContractSettings.gql'

const EditGeneralSettingsForm = ({ generalSettings, setIsOpenedModal }) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const { newNotification } = useNotifications()

  const { data: overdueLevelsData, loading: overdueLevelsLoading } = useCustomQuery({
    query: VendorOverdueLevelGroups,
    queryOptions: {
      variables: { id: currentUser?.vendorId },
      skip: !currentUser?.id,
    },
    rollbarOptions: {
      operationName: 'VendorOverdueLevelGroups',
      target: 'EditGeneralSettingsForm',
    },
  })
  const [updateLienGeneralSettings, { loading }] = useCustomMutation({
    mutation: UpdateLienGeneralSettingsMutation,
    rollbarOptions: {
      operationName: 'UpdateLienGeneralSettingsMutation',
      target: 'EditGeneralSettingsForm',
    },
    mutationOptions: {
      refetchQueries: [LienMonitorVendorSettingQuery, LienMonitorContractSettingsQuery],
    },
  })

  const initialValues = useMemo(
    () => ({
      minimumOutstandingBalanceThresholdEnabled:
        !!generalSettings.minimumOutstandingBalanceThresholdCents,
      exactMatch: generalSettings.exactMatch,
      excludeClaimant: generalSettings.excludeClaimant,
      overdueLevelIds: [
        generalSettings.vendorSettingOverdueLevels?.some(
          (overdueLevelGroup) => overdueLevelGroup.current,
        )
          ? '0'
          : void 0,
        ...(generalSettings.overdueLevelIds || []),
      ].filter(Boolean),
      minimumOutstandingBalanceThresholdCents:
        generalSettings.minimumOutstandingBalanceThresholdCents,
    }),
    [generalSettings],
  )
  const closeForm = useCallback(() => {
    setIsOpenedModal(false)
  }, [setIsOpenedModal])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })
  const handleValidateForm = (values) => {
    const { minimumOutstandingBalanceThresholdEnabled, minimumOutstandingBalanceThresholdCents } =
      values

    if (minimumOutstandingBalanceThresholdEnabled && minimumOutstandingBalanceThresholdCents <= 0) {
      return {
        [FORM_ERROR]: t('invalidLienCustomerOutstandingThreshold'),
      }
    }

    return void 0
  }
  const handleSubmit = (values) => {
    // eslint-disable-next-line no-unused-vars
    const { minimumOutstandingBalanceThresholdEnabled, ...data } = values
    const minimumOutstandingBalanceThresholdCents = minimumOutstandingBalanceThresholdEnabled
      ? data.minimumOutstandingBalanceThresholdCents
      : 0
    const variables = {
      data: {
        ...data,
        minimumOutstandingBalanceThresholdCents,
      },
      vendorId: currentUser.vendorId,
    }
    updateLienGeneralSettings({ variables }).then(({ data }) => {
      const responseData = data?.updateLienMonitorVendorSetting || {}

      if (responseData?.entity) {
        newNotification({ success: t('generalSettingsUpdatedSuccessfully') })
        closeForm()
      }
    })
  }
  const overdueLevelsOptions = useMemo(() => {
    if (!overdueLevelsData?.vendor?.overdueLevelGroups?.length) {
      return []
    }

    return overdueLevelsData.vendor.overdueLevelGroups.map((overdueLevelGroup) => ({
      value: overdueLevelGroup.overdueLevel?.id || '0',
      label: t(getDisplayStatusValue(overdueLevelGroup.overdueLevel)),
    }))
  }, [overdueLevelsData])

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, dirty, touched, error, valid, values }) => {
        useBeforeUnload({ when: touched && dirty })

        if (touched && dirty !== isFormDirty) {
          setDirtyFormState(dirty)
        }

        return (
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <Field name="minimumOutstandingBalanceThresholdEnabled">
              {({ input }) => {
                return (
                  <Flex className="w-full py-5 px-6 gap-5">
                    <Flex className="w-3/5" column>
                      <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                        {t('setMinimumCustomerOutstandingBalance')}
                      </Text>
                      <Text color="text-black-500">
                        {t('setMinimumCustomerOutstandingBalanceDescription')}
                      </Text>
                    </Flex>
                    <Toggle handleChange={input.onChange} value={input.value} />
                  </Flex>
                )
              }}
            </Field>
            <Collapse isOpen={values.minimumOutstandingBalanceThresholdEnabled}>
              <Field name="minimumOutstandingBalanceThresholdCents">
                {({ input }) => {
                  return (
                    <Flex className="w-full py-5 px-6 gap-5 border-t">
                      <Flex className="w-3/5" column>
                        <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                          {t('minimumCustomerOutstandingThreshold')}
                        </Text>
                        <Text color="text-black-500">
                          {t('minimumCustomerOutstandingThresholdDescription')}
                        </Text>
                      </Flex>
                      <CurrencyInput
                        disabled={!values.minimumOutstandingBalanceThresholdEnabled}
                        onChange={(e) => {
                          const value = e.target.value
                          const newValue = Math.round(value * 100)
                          input.onChange(newValue)
                        }}
                        placeholder={t('balance')}
                        value={input.value / 100}
                      />
                    </Flex>
                  )
                }}
              </Field>
            </Collapse>
            <Field name="overdueLevelIds">
              {({ input }) => {
                return (
                  <Flex className="w-full py-5 px-6 gap-5 border-t">
                    <Flex className="w-3/5" column>
                      <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                        {t('setCustomerStatusThreshold')}
                      </Text>
                      <Text color="text-black-500">
                        {t('setCustomerStatusThresholdDescription1')}
                      </Text>
                      <Text color="text-black-500">
                        {t('setCustomerStatusThresholdDescription2')}
                      </Text>
                    </Flex>
                    <NDropdown
                      className="w-[11.5rem]"
                      isDisabled={overdueLevelsLoading}
                      listClass="max-h-40 p-1 pt-0 pb-0"
                      onChange={input.onChange}
                      options={overdueLevelsOptions}
                      placeholder={t('chooseStatus')}
                      value={input.value}
                      isMultipleSelect
                    />
                  </Flex>
                )
              }}
            </Field>

            <Text className="px-6 pt-5 border-t" size={sizes.LG}>
              {t('searchCriteria')}
            </Text>
            <Field name="exactMatch">
              {({ input }) => {
                return (
                  <Flex className="w-full py-5 px-6 gap-5">
                    <Flex className="w-3/5" column>
                      <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                        {t('exactMatch')}
                      </Text>
                      <Text color="text-black-500">{t('exactMatchDescription')}</Text>
                    </Flex>
                    <Toggle handleChange={input.onChange} value={input.value} />
                  </Flex>
                )
              }}
            </Field>
            <Field name="excludeClaimant">
              {({ input }) => {
                return (
                  <Flex className="w-full py-5 px-6 gap-5 border-t">
                    <Flex className="w-3/5" column>
                      <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                        {t('excludeIfClaimant')}
                      </Text>
                      <Text color="text-black-500">{t('excludeIfClaimantDescription')}</Text>
                    </Flex>
                    <Toggle handleChange={input.onChange} value={input.value} />
                  </Flex>
                )
              }}
            </Field>

            <div className="text-error px-6">{error}</div>

            <Flex className="w-full mt-6" justifyContent="end">
              <Button
                className="mr-2"
                label={t('close')}
                onClick={requestClose}
                variant={buttonsVariants.TERTIARY}
              />
              <Button
                disabled={!valid || loading || overdueLevelsLoading}
                label={t('save')}
                type="submit"
              />
            </Flex>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={handleValidateForm}
    />
  )
}

EditGeneralSettingsForm.propTypes = {
  generalSettings: PT.object,
  setIsOpenedModal: PT.func.isRequired,
}

export default EditGeneralSettingsForm
