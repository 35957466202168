import React from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '../../../components/headers/PageHeader'
import { Text } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import LienFinder from './LienFinder'

const LienFinderPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeader className="mb-0" headerTitle={t('lienFinder')} />
      <Text className="pb-6" size={sizes.BASE} paragraph>
        {t('lienFinderDescription')}
      </Text>
      <LienFinder />
    </>
  )
}

export default LienFinderPage
