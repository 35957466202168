import {
  COLLECTION_CATEGORY,
  GENERAL_CATEGORY,
  notificationSettings,
  TRANSACTIONAL_CATEGORY,
} from '../../components/notificationSettingsContent/customerNotificationsConfig'

const applyExcludedOptions = (notificationSettings) => {
  const excludedOptions = []
  const excludeForCreatedByMe = [
    'newInvoices',
    'monthlyStatements',
    'monthlyStatements',
    'documentsUploaded',
    'invoiceDueDateNotification',
    'invoiceDueDate3DaysReminder',
    'invoiceDueDate7DaysReminder',
    'invoicePastDueDateReminder',
    'earlyPaymentDiscountReminder',
    'alternativePaymentSuccessful',
    'paymentInitiated',
    'standardPaymentVoided',
    'alternativePaymentVoided',
    'invoicePaymentRefundCreated',
    'invoicePaymentRefundFailed',
    'depositAuthorization',
    'depositAuthorizationCancelled',
    'depositAuthorizationCaptured',
    'depositReturn',
    'promiseToPayLifecycleEvent',
    'disputeCreatedOrResolved',
    'lienWaiverRequested',
    'autopayEnabled',
    'autopayDisabled',
    'paymentMethodExpirationReminder',
    'paymentMethodCreated',
    'paymentMethodDeleted',
  ]

  if (excludeForCreatedByMe.includes(notificationSettings.name)) {
    excludedOptions.push('createdByMe')
  }

  return { ...notificationSettings, excludedOptions }
}

export const {
  collectionsEmailNotificationSettings,
  generalEmailNotificationSettings,
  transactionalEmailNotificationSettings,
} = {
  generalEmailNotificationSettings: notificationSettings
    .filter(
      (notificationSettings) =>
        notificationSettings.category === GENERAL_CATEGORY &&
        notificationSettings.isDisplayOnMerchant,
    )
    .map((notificationSettings) => ({
      ...applyExcludedOptions(notificationSettings),
      radioKey: 'customerScope',
      radioOptionKey: 'customersScope',
    })),
  collectionsEmailNotificationSettings: notificationSettings
    .filter(
      (notificationSettings) =>
        notificationSettings.category === COLLECTION_CATEGORY &&
        notificationSettings.isDisplayOnMerchant,
    )
    .map((notificationSettings) => ({
      ...applyExcludedOptions(notificationSettings),
      radioKey: 'customerScope',
      radioOptionKey: 'customersScope',
    })),
  transactionalEmailNotificationSettings: notificationSettings
    .filter(
      (notificationSettings) =>
        notificationSettings.category === TRANSACTIONAL_CATEGORY &&
        notificationSettings.isDisplayOnMerchant,
    )
    .map((notificationSettings) => ({
      ...applyExcludedOptions(notificationSettings),
      radioKey: 'customerScope',
      radioOptionKey: 'customersScope',
    })),
}
