import React, { useEffect, useState } from 'react'
import PageHeader from '../../components/headers/PageHeader'
import { Flex, Tabs } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { getActiveTab, getTabsMap } from '../../utils/utils'
import PT from 'prop-types'
import PaymentsTab from './PaymentsTab'
import PromisesToPay from './PromisesToPay'
import PaymentPlans from './PaymentPlans'
import FiltersControlButton from '../../components/filters/FiltersControlButton'
import RefundsTab from './RefundsTab'
import AuthorizationsTab from './AuthorizationsTab'
import SavedTableViewControl from '../../components/savedTableViews/SavedTableViewControl'

const Payments = ({ contractData, isAllContracts }) => {
  const { t } = useTranslation()
  const tabs = ['payments', 'authorizations', 'promisesToPay', 'paymentPlans', 'refunds']
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('')
  const navigate = useNavigate()
  const [entity, setEntity] = useState(null)
  const [filterValidationCompleted, setFilterValidationCompleted] = useState(null)
  const [tableViewControlKey, setTableViewControlKey] = useState()
  const [availableFilters, setAvailableFilters] = useState()

  const handleTabClick = (tab) => {
    navigate(`./${tab}`)
    setActiveTab(tab)
  }

  const tabsMap = getTabsMap(tabs, activeTab, handleTabClick, t)

  useEffect(() => {
    const activeTab = getActiveTab(location, 'payments') || 'payments'
    setActiveTab(activeTab)
    setEntity(`invoices_${activeTab}`)
  }, [location])

  return (
    <>
      <PageHeader headerTitle={t('payments')} />

      <Flex className="justify-between items-center">
        <Tabs tabs={tabsMap} />
        <Flex>
          <SavedTableViewControl
            availableFilters={availableFilters}
            contractId={contractData?.id}
            entity={entity}
            key={tableViewControlKey}
            setFilterValidationCompleted={setFilterValidationCompleted}
          />
          <FiltersControlButton />
        </Flex>
      </Flex>

      <Routes>
        <Route
          element={
            <PaymentsTab
              contractData={contractData}
              filterValidationCompleted={filterValidationCompleted}
              isAllContracts={isAllContracts}
              scope={activeTab}
              setAvailableTabFilters={setAvailableFilters}
              setTableViewControlKey={setTableViewControlKey}
            />
          }
          path="/payments"
        />
        <Route
          element={
            <AuthorizationsTab
              contractData={contractData}
              filterValidationCompleted={filterValidationCompleted}
              isAllContracts={isAllContracts}
              scope={activeTab}
              setAvailableTabFilters={setAvailableFilters}
            />
          }
          path="/authorizations"
        />
        <Route
          element={
            <PromisesToPay
              contractData={contractData}
              filterValidationCompleted={filterValidationCompleted}
              isAllContracts={isAllContracts}
              scope={activeTab}
              setAvailableTabFilters={setAvailableFilters}
            />
          }
          path="/promisesToPay"
        />
        <Route
          element={
            <PaymentPlans
              contractData={contractData}
              filterValidationCompleted={filterValidationCompleted}
              isAllContracts={isAllContracts}
              scope={activeTab}
              setAvailableTabFilters={setAvailableFilters}
            />
          }
          path="/paymentPlans"
        />
        <Route
          element={
            <RefundsTab
              contractData={contractData}
              filterValidationCompleted={filterValidationCompleted}
              isAllContracts={isAllContracts}
              scope={activeTab}
              setAvailableTabFilters={setAvailableFilters}
            />
          }
          path="/refunds"
        />
        <Route
          element={
            <PaymentsTab
              contractData={contractData}
              filterValidationCompleted={filterValidationCompleted}
              isAllContracts={isAllContracts}
              scope={activeTab}
              setAvailableTabFilters={setAvailableFilters}
              setTableViewControlKey={setTableViewControlKey}
            />
          }
          path="/"
        />
      </Routes>
    </>
  )
}

Payments.propTypes = {
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  isAllContracts: PT.bool,
}

Payments.defaultProps = {
  contractData: {},
  isAllContracts: false,
}

export default Payments
