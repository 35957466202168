import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import cx from 'classnames'
import { Button, Collapse, ConfirmationDialog, Flex, Icon } from '../../ui-kit'
import colors from '../../ui-kit/colors'
import getNavigationMap from '../../navigationMap'
import { useTranslation } from 'react-i18next'
import { logo, logoIcon } from '../../ui-kit/assets'
import PT from 'prop-types'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import SyncExceptions from '../syncExceptions/SyncExceptions'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { membershipRoles } from '../../pages/settings/teamManagement/membershipRoles'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { useNotifications } from '../../hooks/useNotifications'
import { CreateERPExport } from '../../queriesUpdated/mutations/createErpFileExport.gql'

const isCurrent = (item, location) =>
  item.root
    ? location.pathname.match(`^${item.href}`) || location.pathname === '/'
    : location.pathname.match(`^${item.href}`)

const NavigationWithOptions = ({ item, className, iconColor, iconClassName, isMenuCollapsed }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [isExpanded, setIsExpanded] = useState(!!isCurrent(item, location))

  return (
    <>
      <div
        className={cx(className, 'relative cursor-pointer')}
        onClick={() => setIsExpanded(!isExpanded)}>
        <Icon
          aria-hidden="true"
          className={iconClassName}
          color={iconColor}
          name={item.icon}
          type="outline"
        />
        {!isMenuCollapsed && t(item.name)}
        {isMenuCollapsed && <span className="text-xs">{t(item.name)}</span>}
        <Icon
          className={cx('w-10 h-5 right-0', !isMenuCollapsed && 'absolute')}
          color={iconColor}
          name={isExpanded ? 'chevronUp' : 'chevronDown'}
        />
      </div>
      <Collapse isOpen={isExpanded}>
        <ul>
          {item.subItems.map((subItem) => (
            <li
              className={cx(
                isCurrent(subItem, location) && 'bg-primary-100',
                'group hover:bg-gray-100 flex rounded-md',
              )}
              key={subItem.name}>
              <NavLink
                className={cx(
                  isCurrent(subItem, location)
                    ? 'primary hover:text-primary-700'
                    : 'text-gray-600 hover:text-gray-900',
                  'w-full hover:no-underline px-2 py-2 text-sm font-medium',
                  isMenuCollapsed && 'text-center',
                  !isMenuCollapsed && 'pl-11',
                )}
                key={subItem.name}
                to={subItem.href}>
                {t(subItem.name)}
              </NavLink>
            </li>
          ))}
        </ul>
      </Collapse>
    </>
  )
}

NavigationWithOptions.propTypes = {
  item: PT.shape({
    icon: PT.string,
    name: PT.string,
    href: PT.string,
    subItems: PT.arrayOf({
      name: PT.string,
      href: PT.string,
    }),
  }),
  iconColor: PT.string,
  className: PT.string,
  iconClassName: PT.string,
  isMenuCollapsed: PT.bool,
}

const Navigation = ({ isMenuCollapsed }) => {
  const location = useLocation()
  const currentUser = useCurrentUser()
  const [isConfirmationPushOpen, setIsConfirmationPushOpen] = useState(false)
  const { newNotification } = useNotifications()

  const [createErpFileExport, { loading: isErpFileExportLoading }] = useCustomMutation({
    mutation: CreateERPExport,
    rollbarOptions: { operationName: 'CreateERPExport', target: 'ERPFiles' },
  })

  const handlePushSync = () => {
    setIsConfirmationPushOpen(true)
  }

  const handleSubmitPush = () => {
    createErpFileExport().then(({ data }) => {
      const responseData = data?.createErpFileExport || {}
      if (responseData?.entity) {
        newNotification({ success: t('erpFileExportCreated') })
      }
    })
    setIsConfirmationPushOpen(false)
  }

  const activeLinkClasses = (item, isMenuCollapsed) =>
    cx(
      isCurrent(item, location)
        ? 'bg-primary-100 primary hover:text-primary-700'
        : 'text-gray-600 hover:text-gray-900',
      'group hover:bg-gray-100 hover:no-underline flex items-center px-2 py-2 text-sm font-medium rounded-md',
      isMenuCollapsed ? 'flex-col' : '',
    )

  const activeIconClasses = (item) =>
    cx(
      isCurrent(item, location) ? 'primary' : 'text-gray-400 group-hover:text-gray-500',
      'flex-shrink-0 h-6 w-6',
      isMenuCollapsed ? '' : 'mr-3',
    )
  const { t } = useTranslation()

  return (
    <>
      <div className={`flex flex-col ${isMenuCollapsed ? 'w-20' : 'w-44'}`}>
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4 justify-center">
              <img alt="Suppli" className="h-8" src={isMenuCollapsed ? logoIcon : logo} />
            </div>

            <Flex
              className={`mt-5 space-y-1 ${!isMenuCollapsed && 'px-2'}`}
              flex={false}
              grow={1}
              shrink={1}
              tag="nav"
              testData="navigation-items-container">
              {getNavigationMap(currentUser?.membershipRole, currentUser.featureFlags).reduce(
                (acc, item) => {
                  if (item.hidden) {
                    return acc
                  }

                  if (!item.subItems) {
                    acc.push(
                      <NavLink
                        className={activeLinkClasses(item, isMenuCollapsed)}
                        key={item.name}
                        to={item.href}>
                        <Icon
                          aria-hidden="true"
                          className={activeIconClasses(item, isMenuCollapsed)}
                          color={isCurrent(item, location) ? colors.PRIMARY : colors.GREY}
                          name={item.icon}
                          type="outline"
                        />
                        {!isMenuCollapsed && t(item.name)}
                        {isMenuCollapsed && <span className="text-xs">{t(item.name)}</span>}
                      </NavLink>,
                    )
                  } else {
                    acc.push(
                      <NavigationWithOptions
                        className={activeLinkClasses(item, isMenuCollapsed)}
                        iconClassName={activeIconClasses(item, isMenuCollapsed)}
                        iconColor={isCurrent(item, location) ? colors.PRIMARY : colors.GREY}
                        isMenuCollapsed={isMenuCollapsed}
                        item={item}
                      />,
                    )
                  }

                  return acc
                },
                [],
              )}
              {currentUser.erpMetadata?.showErpSyncErrors && (
                <SyncExceptions className={cx('pt-16 px-2', !isMenuCollapsed && 'px-2')} />
              )}
              {currentUser.membershipRole === membershipRoles.ADMIN &&
                currentUser.erpMetadata?.erpSyncButtonEnabled && (
                  <div className="text-center px-2 w-full">
                    <Button
                      className="px-2 my-4 w-25 w-full"
                      isLoading={isErpFileExportLoading}
                      label={t('pushSync')}
                      onClick={handlePushSync}
                      size={sizes.SM}
                      testData="push-sync"
                      variant={buttonsVariants.SECONDARY}
                    />
                  </div>
                )}
            </Flex>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        confirmationMessage={t('confirmationSubmitPush')}
        isOpened={isConfirmationPushOpen}
        onSubmitButtonNoClick={() => setIsConfirmationPushOpen(false)}
        onSubmitButtonYesClick={handleSubmitPush}
        setIsOpened={setIsConfirmationPushOpen}
        submitButtonYesLabel={t('ok')}
        title={t('pushSync')}
        withHideNoBtn
      />
    </>
  )
}

Navigation.propTypes = {
  isMenuCollapsed: PT.bool,
}
Navigation.defaultProps = {
  isMenuCollapsed: false,
}

export default Navigation
