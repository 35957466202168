import { DateTime } from 'luxon'
import { getFromAvailableFilters } from './getFromAvailableFilters'
import { filterChange } from './filterChange'
import { checkIfConvert } from './checkIfConvert'

export const parseCurrentUserFilters = (filterOptions, t, updateFilterList) => {
  if (!t || !updateFilterList) {
    return
  }

  const newFilterList = filterOptions.map((filter) => {
    return {
      key: filter.value,
      label: filter.label,
      type: filter.type,
      options: filter.filterOptions?.map((item) => ({
        value: item.key,
        label: t(item.title),
      })),
      dataType: filter.dataType,
      loadOptions: filter.loadOptions,
      ignoreMultipleSelect: filter.ignoreMultipleSelect,
      wrapperClassName: filter.wrapperClassName,
      withSelectAll: filter.withSelectAll,
      maxDate: filter.maxDate,
      wholeDay: filter.wholeDay,
      disabled: filter.disabled,
      required: filter.required,
      excludedActions: filter.excludedActions,
      hint: filter.hint,
    }
  })
  updateFilterList(newFilterList)
}

export const getAvailableFilterOptions = (availableFilters, columns) => {
  if (!availableFilters || !columns) {
    return []
  }

  return columns.reduce((acc, column) => {
    if (column.filterId) {
      const filter = getFromAvailableFilters(availableFilters, column.filterId)
      filter &&
        acc.push({
          label: column.filterTitle || column.headerName,
          value: column.filterId,
          type: column.filterType || filter?.type,
          dataType: column.filterDataType,
          filterOptions: column.filterOptions || filter?.options,
          loadOptions: column.loadOptions,
          ignoreMultipleSelect: column.ignoreMultipleSelect,
          wrapperClassName: column.filterWrapperClassName,
          withSelectAll: column.withSelectAll,
          maxDate: column.maxDate,
          wholeDay: column.wholeDay,
          disabled: column.disabled,
          required: column.required,
          excludedActions: column.excludedActions,
          hint: column.hint,
        })
    }
    return acc
  }, [])
}

export const filterValueChange = ({
  value,
  key,
  isApplyOnBlur,
  selectedFilter,
  setSelectedFilters,
  setQueryParams,
  refs,
}) => {
  let valueArray = value.split(',')

  if (valueArray.length === 1 && valueArray[0] === '') {
    valueArray = []
  }

  filterChange({
    value: valueArray,
    key,
    isApplyOnBlur,
    selectedFilter,
    setSelectedFilters,
    setQueryParams,
    refs,
  })
}

export const dateFilterChange = ({
  date,
  key,
  selectedFilter,
  setSelectedFilters,
  setQueryParams,
  refs,
  wholeDay,
}) => {
  if (date) {
    date = wholeDay
      ? DateTime.fromJSDate(date).endOf('day').toISO()
      : DateTime.fromJSDate(date).toISO()
  }

  filterChange({
    value: date,
    key,
    isApplyOnBlur: false,
    selectedFilter,
    setSelectedFilters,
    setQueryParams,
    refs,
  })
}

export const datesFilterChange = ({
  dates,
  key,
  selectedFilter,
  setSelectedFilters,
  setQueryParams,
  refs,
}) => {
  if (!Array.isArray(dates)) {
    return
  }

  const formattedDates = dates
    .map((date, index) => {
      if (!date) {
        return ''
      }

      if (index > 0) {
        return DateTime.fromJSDate(date).endOf('day').toISO()
      }

      return DateTime.fromJSDate(date).toISO()
    })
    .filter(Boolean)

  filterChange({
    value: formattedDates,
    key,
    isApplyOnBlur: false,
    selectedFilter,
    setSelectedFilters,
    setQueryParams,
    refs,
  })
}

export const convertToCents = (value, filter) => {
  return checkIfConvert(value, filter) ? value * 100 : value
}

export const convertToDollar = (value, filter) => {
  return checkIfConvert(value, filter) ? value / 100 : value
}

export const autocompleteValuesChange = ({
  values,
  key,
  selectedFilter,
  setSelectedFilters,
  setQueryParams,
  refs,
  selectedOptions,
  setSelectedOptions,
}) => {
  let valuesArray = []

  if (Array.isArray(values)) {
    valuesArray = values.map((item) => item.value)
  } else {
    valuesArray = values ? [values] : []
  }

  setSelectedOptions?.({ ...selectedOptions, [key]: values })
  filterChange({
    value: valuesArray,
    key,
    isApplyOnBlur: false,
    selectedFilter,
    setSelectedFilters,
    setQueryParams,
    refs,
  })
}
