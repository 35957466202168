import React, { useCallback, useMemo, useRef } from 'react'
import PT from 'prop-types'
import DatePickerLibComponent, { CalendarContainer } from 'react-datepicker'
import styles from './FilterDatePicker.module.scss'
import cx from 'classnames'
import Icon from '../icons/Icon'
import 'react-datepicker/dist/react-datepicker.css'
import Input from '../inputs/Input'
import { DEFAULT_DATE_FORMAT } from '../../utils/dateUtils'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { getDateRange } from './logic'

const FilterDatePicker = ({
  startDate,
  endDate,
  onChange,
  onBlur,
  placeholder = 'Select Date',
  error,
  className,
  testData,
  selected,
  singleDate,
  maxDate,
  excludedActions,
  ...props
}) => {
  const { t } = useTranslation()
  const pickerRef = useRef()
  const closeDatePicker = useCallback(() => {
    pickerRef?.current?.setOpen?.(false)
  }, [pickerRef.current])
  const actions = useMemo(
    () =>
      [
        !excludedActions.includes('nextWeek') && {
          label: t('nextWeek'),
          onClick: () => {
            const [startDate, endDate] = getDateRange('plus', { days: 7 })
            onChange([startDate, endDate])
            closeDatePicker()
          },
        },
        !excludedActions.includes('last14Days') && {
          label: t('last14Days'),
          onClick: () => {
            const [startDate, endDate] = getDateRange('minus', { days: 14 })
            onChange([startDate, endDate])
            closeDatePicker()
          },
        },
        !excludedActions.includes('last30Days') && {
          label: t('last30Days'),
          onClick: () => {
            const [startDate, endDate] = getDateRange('minus', { days: 30 })
            onChange([startDate, endDate])
            closeDatePicker()
          },
        },
        !excludedActions.includes('last3Months') && {
          label: t('last3Months'),
          onClick: () => {
            const [startDate, endDate] = getDateRange('minus', { months: 3 })
            onChange([startDate, endDate])
            closeDatePicker()
          },
        },
        !excludedActions.includes('last12Months') && {
          label: t('last12Months'),
          onClick: () => {
            const [startDate, endDate] = getDateRange('minus', { months: 12 })
            onChange([startDate, endDate])
            closeDatePicker()
          },
        },
        !excludedActions.includes('monthToDate') && {
          label: t('monthToDate'),
          onClick: () => {
            const startDate = DateTime.now().startOf('month').toJSDate()
            const endDate = DateTime.now().startOf('day').toJSDate()
            onChange([startDate, endDate])
            closeDatePicker()
          },
        },
      ].filter(Boolean),
    [closeDatePicker, onChange, t],
  )

  // eslint-disable-next-line react/prop-types
  const CustomContainer = ({ className, children }) => {
    const calendarWrapperClasses = 'flex flex-col'
    const calendarClasses = cx(className, {
      'react-datepicker--with-actions': !!actions?.length,
    })
    return (
      <div>
        <CalendarContainer className="flex shadow-4-22-012">
          {!singleDate && actions?.length ? (
            <div className="flex flex-col w-[200px] bg-white border-gray-200 border-r py-4">
              {actions.map((action) => (
                <div
                  className="px-6 my-2 cursor-pointer"
                  key={action.label}
                  onClick={action.onClick}>
                  {action.label}
                </div>
              ))}
            </div>
          ) : null}
          <div className={calendarWrapperClasses}>
            <div className={calendarClasses}>{children}</div>
          </div>
        </CalendarContainer>
      </div>
    )
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      <DatePickerLibComponent
        dateFormat={DEFAULT_DATE_FORMAT}
        ref={pickerRef}
        {...props}
        calendarContainer={CustomContainer}
        closeOnScroll={(e) => e.target === document}
        customInput={
          <Input
            error={error}
            icon={
              <Icon
                color={'primary-700'}
                name={'calendar'}
                onClick={(e) => {
                  e.preventDefault()
                }}
                type={'solid'}
              />
            }
            testData={testData}
          />
        }
        endDate={endDate}
        maxDate={maxDate}
        monthsShown={singleDate ? 1 : 2}
        onBlur={onBlur}
        onChange={onChange}
        onChangeRaw={(event) => {
          const inputValue = event.target.value?.replace(/\s/g, '')
          if (inputValue?.length <= 1) {
            onChange([null, null])
          }
          if (inputValue?.length >= 21) {
            const [startDate, endDate] = inputValue.split('-')
            onChange([new Date(startDate), new Date(endDate)])
          }
        }}
        onKeyDown={(e) => {
          const allowedSymbols = [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '0',
            '/',
            '.',
            '-',
            'Backspace',
            'ArrowLeft',
            'ArrowRight',
          ]
          if (!allowedSymbols.includes(e.key)) {
            e.preventDefault()
          }
        }}
        placeholderText={placeholder}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        portalId="filter-datepicker-portal"
        selected={selected}
        selectsRange={!singleDate}
        startDate={startDate}
        strictParsing={true}
        shouldCloseOnSelect
      />
    </div>
  )
}

FilterDatePicker.propTypes = {
  error: PT.bool,
  className: PT.string,
  endDate: PT.string,
  startDate: PT.string,
  onChange: PT.func,
  onBlur: PT.func,
  placeholder: PT.string,
  testData: PT.string,
  selected: PT.string,
  singleDate: PT.bool,
  maxDate: PT.any,
  excludedActions: PT.arrayOf(PT.string),
}

FilterDatePicker.defaultProps = {
  error: false,
  className: '',
  endDate: '',
  startDate: '',
  onChange: () => {},
  onBlur: () => {},
  placeholder: null,
  selected: '',
  singleDate: false,
  excludedActions: [],
}

export default FilterDatePicker
