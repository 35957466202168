import { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withAsyncPaginate } from 'react-select-async-paginate'
import Select from './Select'

const CustomAsyncPaginate = withAsyncPaginate(Select)

const AutocompleteSelect = (props) => {
  const ref = useRef(null)
  const onChangeHandler = useCallback(
    (option) => {
      if (props.isMulti) {
        props.onChange?.(option)
      } else {
        props.onChange?.(option?.value)
      }
      ref.current?.blur?.()
    },
    [props?.onChange, ref.current],
  )

  const value = useMemo(
    () =>
      props.isMulti
        ? props?.options?.filter?.((option) => props?.value?.indexOf(option.value) > -1)
        : props?.options?.find?.((option) => option.value === props.value),
    [props.options, props.value],
  )

  return (
    <CustomAsyncPaginate
      {...props}
      className={cx('rounded-md', props.className)}
      debounceTimeout={300}
      onChange={onChangeHandler}
      options={[]}
      selectRef={ref}
      value={value}
      cacheOptions
    />
  )
}

AutocompleteSelect.propTypes = {
  isMulti: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default AutocompleteSelect
