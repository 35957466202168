import React, { useCallback, useEffect, useState, useMemo } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { Flex, Icon, Input, Text, Tooltip } from '../../../ui-kit'
import PT from 'prop-types'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import FilterDatePicker from '../../../ui-kit/components/datePicker/FilterDatePicker'
import { getDateToDatePicker, parseFilterQuery } from '../../../utils/utils'
import {
  autocompleteValuesChange,
  convertToCents,
  convertToDollar,
  dateFilterChange,
  datesFilterChange,
  filterValueChange,
  getAvailableFilterOptions,
  parseCurrentUserFilters,
} from '../../reports/reportsFilters/logic/logic'
import { filterChange } from '../../reports/reportsFilters/logic/filterChange'
import { applyFilters } from '../../reports/reportsFilters/logic/applyFilters'
import FormSelect from '../../../ui-kit/components/Select/FormSelect'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { useQueryParams } from '../../../hooks/useQueryParams'

const SearchFilters = ({
  availableFilters,
  columns,
  refs,
  contentWrapperClassName,
  selectedFilter,
  setSelectedFilters,
}) => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const [filtersList, updateFilterList] = useState([])
  const [selectedOptions, setSelectedOptions] = useState({})
  const filtersQuery = queryParams.filters || null
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery, false), [filtersQuery])

  useEffect(() => {
    const availableFilterOptions = getAvailableFilterOptions(availableFilters, columns)
    parseCurrentUserFilters(availableFilterOptions, t, updateFilterList)
  }, [columns, availableFilters, t])

  useEffect(() => {
    if (userFilters) {
      const initialValues = Object.keys(userFilters).reduce((acc, filterKey) => {
        acc[filterKey] = userFilters[filterKey]
        return acc
      }, {})
      setSelectedFilters(initialValues)
    }
  }, [])

  const getFilterInput = useCallback(
    (filter) => {
      const placeholderRequiredMark =
        typeof filter.required === 'boolean'
          ? filter.required
            ? ` (${t('required')})`
            : ` (${t('optional')})`
          : ''

      switch (filter.type) {
        case 'autocomplete_select':
          return (
            <div className={cx('w-1/5 pb-4', filter.wrapperClassName)}>
              <FormSelect
                hint={filter.hint}
                isMulti={!filter.ignoreMultipleSelect}
                label={filter.label}
                loadOptions={filter.loadOptions}
                onChange={(values) =>
                  autocompleteValuesChange({
                    values,
                    key: filter.key,
                    selectedFilter,
                    setSelectedFilters,
                    refs,
                    selectedOptions,
                    setSelectedOptions,
                  })
                }
                options={selectedOptions[filter.key] || []}
                placeholder={t('choose') + ' ' + filter.label}
                testData={filter.key}
                value={selectedFilter[filter.key]}
                withCheckmarks
              />
            </div>
          )
        case 'select':
          return (
            <div className={cx('w-1/5 pb-4', filter.wrapperClassName)}>
              <NDropdown
                hint={filter.hint}
                inputClassName={'w-full'}
                isMultipleSelect={!filter.ignoreMultipleSelect}
                key={filter.key}
                label={filter.label}
                listClass={'max-h-40'}
                name={filter.key}
                onChange={(e) => {
                  filterChange({
                    value: e.target.value,
                    key: filter.key,
                    isApplyOnBlur: false,
                    selectedFilter,
                    setSelectedFilters,
                    refs,
                  })
                }}
                options={filter.options}
                placeholder={`${t('choose')} ${filter.label}${placeholderRequiredMark}`}
                testData={filter.key}
                value={selectedFilter[filter.key] || (!filter.ignoreMultipleSelect ? [] : '')}
                withSelectAll={filter.withSelectAll}
              />
            </div>
          )
        case 'value':
          return (
            <div className={cx('w-1/5 pb-4', filter.wrapperClassName)}>
              <Input
                hint={filter.hint}
                id={filter.key}
                label={filter.label}
                onBlur={() => applyFilters({ filters: selectedFilter, refs })}
                onChange={({ target }) =>
                  filterValueChange({
                    value: target.value,
                    key: filter.key,
                    isApplyOnBlur: true,
                    selectedFilter,
                    setSelectedFilters,
                    refs,
                  })
                }
                placeholder={`${t('enter')} ${filter.label}${placeholderRequiredMark}`}
                testData={`filter-value-${filter.key}`}
                value={selectedFilter[filter.key]}
              />
            </div>
          )
        case 'values_range': {
          return (
            <Flex alignItems="end" className={cx('flex w-1/5 pb-4', filter.wrapperClassName)}>
              <Input
                id={filter.key + '/0'}
                label={filter.label}
                onBlur={() => applyFilters({ filters: selectedFilter, refs })}
                onChange={({ target }) =>
                  filterChange({
                    value: convertToCents(target.value, filter),
                    key: filter.key + '/0',
                    isApplyOnBlur: true,
                    selectedFilter,
                    setSelectedFilters,
                    refs,
                  })
                }
                placeholder={t('min')}
                testData={`filter-values-range-${filter.key}-min`}
                value={convertToDollar(selectedFilter[filter.key]?.[0], filter)}
                isCurrencyInput
              />
              <Input
                id={filter.key + '/1'}
                onBlur={() => applyFilters({ filters: selectedFilter, refs })}
                onChange={({ target }) =>
                  filterChange({
                    value: convertToCents(target.value, filter),
                    key: filter.key + '/1',
                    isApplyOnBlur: true,
                    selectedFilter,
                    setSelectedFilters,
                    refs,
                  })
                }
                placeholder={t('max')}
                testData={`filter-values-range-${filter.key}-max`}
                value={convertToDollar(selectedFilter[filter.key]?.[1], filter)}
                isCurrencyInput
              />
            </Flex>
          )
        }
        case 'date':
          return (
            <div className={cx('w-1/5 pb-4', filter.wrapperClassName)}>
              <Flex className="gap-x-1">
                <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
                  {filter.label}
                </label>
                {filter.hint && (
                  <Tooltip content={filter.hint}>
                    <Icon color="text-warmBlack-400" name="informationCircle" type="outline" />
                  </Tooltip>
                )}
              </Flex>
              <FilterDatePicker
                maxDate={filter.maxDate}
                onChange={(dateValue) =>
                  dateFilterChange({
                    date: dateValue,
                    key: filter.key,
                    selectedFilter,
                    setSelectedFilters,
                    refs,
                    wholeDay: filter.wholeDay,
                  })
                }
                placeholder={`${t('chooseDate')}${placeholderRequiredMark}`}
                selected={getDateToDatePicker(selectedFilter[filter.key])}
                testData={`filter-date-picker-${filter.key}`}
                singleDate
              />
            </div>
          )
        case 'dates_range':
        case 'date_times_range':
          return (
            <div className={cx('w-1/5 pb-4', filter.wrapperClassName)}>
              <Flex className="gap-x-1">
                <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
                  {filter.label}
                </label>
                {filter.hint && (
                  <Tooltip content={filter.hint}>
                    <Icon color="text-warmBlack-400" name="informationCircle" type="outline" />
                  </Tooltip>
                )}
              </Flex>
              <FilterDatePicker
                endDate={getDateToDatePicker(selectedFilter[filter.key]?.[1])}
                excludedActions={filter.excludedActions}
                maxDate={filter.maxDate}
                onChange={(dateValue) =>
                  datesFilterChange({
                    dates: dateValue,
                    key: filter.key,
                    selectedFilter,
                    setSelectedFilters,
                    refs,
                  })
                }
                placeholder={`${t('chooseDates')}${placeholderRequiredMark}`}
                startDate={getDateToDatePicker(selectedFilter[filter.key]?.[0])}
                testData={`filter-date-picker-${filter.key}`}
              />
            </div>
          )
        case 'boolean':
          return (
            <div className={cx('w-1/5 pb-4', filter.wrapperClassName)}>
              <Flex className="gap-x-1">
                <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
                  {filter.label}
                </label>
                {filter.hint && (
                  <Tooltip content={filter.hint}>
                    <Icon color="text-warmBlack-400" name="informationCircle" type="outline" />
                  </Tooltip>
                )}
              </Flex>
              <Toggle
                className="p-4 align-bottom h-8"
                disabled={filter.disabled}
                handleChange={(val) => {
                  filterChange({
                    value: String(val),
                    key: filter.key,
                    isApplyOnBlur: false,
                    selectedFilter,
                    setSelectedFilters,
                    refs,
                  })
                }}
                id={filter.key}
                key={filter.key}
                name={filter.key}
                testData={`filter-toggle-${filter.key}`}
                value={selectedFilter[filter.key] === 'true'}
              />
            </div>
          )
        default:
          return null
      }
    },
    [t, selectedFilter, refs],
  )

  const removeAllFilters = useCallback(() => {
    setSelectedFilters({})
  }, [])

  return (
    <div className="mt-6">
      {Object.keys(selectedFilter).length ? (
        <Text className="cursor-pointer" color="text-blue-500" onClick={removeAllFilters}>
          {t('clearAll')}
        </Text>
      ) : (
        <div className="h-[21px]" />
      )}
      <Flex className={cx('w-full mt-6 gap-x-4', contentWrapperClassName)} wrap>
        {filtersList.map((filter) => getFilterInput(filter))}
      </Flex>
    </div>
  )
}

SearchFilters.propTypes = {
  refs: PT.object,
  columns: PT.arrayOf(
    PT.shape({
      field: PT.string,
    }),
  ),
  selectedFilter: PT.object,
  availableFilters: PT.arrayOf(
    PT.shape({
      key: PT.string,
      type: PT.string,
    }),
  ),
  contentWrapperClassName: PT.string,
  setSelectedFilters: PT.func.isRequired,
}

SearchFilters.defaultProps = {
  refs: [],
  columns: [],
  selectedFilter: {},
  defaultFilters: {},
  availableFilters: null,
}

export default SearchFilters
