import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { applyUserSignOut } from '../../store/actions/auth'
import { Button, Input, Text } from '../../ui-kit'
import colors from '../../ui-kit/colors'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { validateEmail, validateRequiredField } from '../../utils/validators'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import LoginWrapTemplate from './LoginWrapTemplate'
import fontWeight from '../../ui-kit/fontWeight'
import { useRollbar, useRollbarPerson } from '@rollbar/react'
import { ROLLBAR_NO_AUTH_PERSON } from '../../utils/rollbar'
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from '../../constants/contacts'
import { authorizeUserByCredentials } from './logic/logic'

const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const rollbar = useRollbar()
  useRollbarPerson(ROLLBAR_NO_AUTH_PERSON)

  useEffect(() => {
    dispatch(applyUserSignOut())
  }, [])

  const handleSubmit = useCallback(
    async (values) => await authorizeUserByCredentials(values, t, dispatch, navigate, rollbar),
    [t, dispatch, navigate, rollbar],
  )

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <LoginWrapTemplate>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
                {t('loginToYourAccount')}
              </Text>
              <Field name="email">
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t(input.name)}
                      placeholder="your_email@example.com"
                      type="email"
                      {...input}
                    />
                  )
                }}
              </Field>
              <Field name="password">
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t(input.name)}
                      placeholder={t('yourPassword')}
                      type="password"
                      {...input}
                    />
                  )
                }}
              </Field>
              {submitError && (
                <Text className="block" color="error">
                  {submitError}
                </Text>
              )}
              <div className="text-left">
                <Button
                  label={t('forgotPassword')}
                  linkTo="/auth/login/reset_password"
                  testData="forgot-password"
                  variant={buttonsVariants.PRIMARY_LINK}
                />
              </div>
              <Button
                color={colors.PRIMARY}
                id="login-button"
                label={t('login')}
                testData="login"
                type="submit"
                fullWidth
              />
              <div>
                <Text color="text-warmBlack-400 mt-2" size={sizes.XS}>
                  {t('loginWarning')}
                </Text>
                <Text className={'ml-1'} color="blue mt-2" size={sizes.XS}>
                  <a href={TERMS_OF_SERVICE}>{t('termsOfService')}</a>
                </Text>
                <Text className={'ml-1'} color="text-warmBlack-400 mt-2" size={sizes.XS}>
                  {t('and')}
                </Text>
                <Text className={'ml-1'} color="blue mt-2" size={sizes.XS}>
                  <a href={PRIVACY_POLICY}>{t('privacyPolicy')}</a>.
                </Text>
              </div>
            </LoginWrapTemplate>
          </form>
        )
      }}
      validate={(values) => ({
        email: validateRequiredField(values.email) || validateEmail(values.email),
        password: validateRequiredField(values.password),
      })}
    />
  )
}

export default Login
