export const finderAvailableFilters = [
  {
    key: 'party_name',
    type: 'value',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'exact_match',
    type: 'boolean',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'date',
    type: 'dates_range',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'address',
    type: 'value',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'city',
    type: 'value',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'state',
    type: 'select',
    options: [],
    __typename: 'Filter',
  },
  {
    key: 'project_address',
    type: 'value',
    options: [],
    __typename: 'Filter',
  },
]
