import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { Flex, Input, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import PT from 'prop-types'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import FilterDatePicker from '../../../ui-kit/components/datePicker/FilterDatePicker'
import { getDateToDatePicker } from '../../../utils/utils'
import {
  autocompleteValuesChange,
  convertToCents,
  convertToDollar,
  dateFilterChange,
  datesFilterChange,
  filterValueChange,
  getAvailableFilterOptions,
  parseCurrentUserFilters,
} from './logic/logic'
import { filterChange } from './logic/filterChange'
import { applyFilters } from './logic/applyFilters'
import FormSelect from '../../../ui-kit/components/Select/FormSelect'
import Toggle from '../../../ui-kit/components/inputs/Toggle'

const ReportsFilters = ({ availableFilters, columns, refs }) => {
  const { t } = useTranslation()
  const { setQueryParams, removeQueryParam } = useQueryParams()
  const [filtersList, updateFilterList] = useState([])
  const [selectedFilter, setSelectedFilters] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})

  useEffect(() => {
    removeQueryParam('filters')
  }, [])

  useEffect(() => {
    const availableFilterOptions = getAvailableFilterOptions(availableFilters, columns)
    parseCurrentUserFilters(availableFilterOptions, t, updateFilterList)
  }, [columns, availableFilters, t])

  const getFilterInput = useCallback(
    (filter) => {
      const elementWrapperClassName = 'w-[calc(20%-theme(spacing.4))]'

      switch (filter.type) {
        case 'autocomplete_select':
          return (
            <div className={cx(elementWrapperClassName, filter.wrapperClassName)}>
              <FormSelect
                isMulti={!filter.ignoreMultipleSelect}
                label={filter.label}
                loadOptions={filter.loadOptions}
                onChange={(values) =>
                  autocompleteValuesChange({
                    values,
                    key: filter.key,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                    selectedOptions,
                    setSelectedOptions,
                  })
                }
                options={selectedOptions[filter.key] || []}
                placeholder={t('choose') + ' ' + filter.label}
                testData={filter.key}
                value={selectedFilter[filter.key]}
                withCheckmarks
              />
            </div>
          )
        case 'select':
          return (
            <div className={cx(elementWrapperClassName, filter.wrapperClassName)}>
              <NDropdown
                inputClassName={'w-full'}
                isMultipleSelect={!filter.ignoreMultipleSelect}
                key={filter.key}
                label={filter.label}
                listClass={'max-h-40'}
                name={filter.key}
                onChange={(e) => {
                  filterChange({
                    value: e.target.value,
                    key: filter.key,
                    isApplyOnBlur: false,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  })
                }}
                options={filter.options}
                placeholder={`${t('choose')} ${filter.label}`}
                testData={filter.key}
                value={selectedFilter[filter.key] || []}
                withSelectAll={filter.withSelectAll}
              />
            </div>
          )
        case 'value':
          return (
            <div className={cx(elementWrapperClassName, filter.wrapperClassName)}>
              <Input
                id={filter.key}
                label={filter.label}
                onBlur={() => applyFilters({ filters: selectedFilter, setQueryParams, refs })}
                onChange={({ target }) =>
                  filterValueChange({
                    value: target.value,
                    key: filter.key,
                    isApplyOnBlur: true,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  })
                }
                placeholder={`${t('enter')} ${filter.label}`}
                testData={`filter-value-${filter.key}`}
                value={selectedFilter[filter.key]}
              />
            </div>
          )
        case 'values_range': {
          return (
            <Flex
              alignItems="end"
              className={cx('flex', elementWrapperClassName, filter.wrapperClassName)}>
              <Input
                id={filter.key + '/0'}
                label={filter.label}
                onBlur={() => applyFilters({ filters: selectedFilter, setQueryParams, refs })}
                onChange={({ target }) =>
                  filterChange({
                    value: convertToCents(target.value, filter),
                    key: filter.key + '/0',
                    isApplyOnBlur: true,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  })
                }
                placeholder={t('min')}
                testData={`filter-values-range-${filter.key}-min`}
                value={convertToDollar(selectedFilter[filter.key]?.[0], filter)}
                isCurrencyInput
              />
              <Input
                id={filter.key + '/1'}
                onBlur={() => applyFilters({ filters: selectedFilter, setQueryParams, refs })}
                onChange={({ target }) =>
                  filterChange({
                    value: convertToCents(target.value, filter),
                    key: filter.key + '/1',
                    isApplyOnBlur: true,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  })
                }
                placeholder={t('max')}
                testData={`filter-values-range-${filter.key}-max`}
                value={convertToDollar(selectedFilter[filter.key]?.[1], filter)}
                isCurrencyInput
              />
            </Flex>
          )
        }
        case 'date':
          return (
            <div className={cx(elementWrapperClassName, filter.wrapperClassName)}>
              <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
                {filter.label}
              </label>
              <FilterDatePicker
                maxDate={filter.maxDate}
                onChange={(dateValue) =>
                  dateFilterChange({
                    date: dateValue,
                    key: filter.key,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                    wholeDay: filter.wholeDay,
                  })
                }
                placeholder={t('chooseDate')}
                selected={getDateToDatePicker(selectedFilter[filter.key])}
                testData={`filter-date-picker-${filter.key}`}
                singleDate
              />
            </div>
          )
        case 'dates_range':
        case 'date_times_range':
          return (
            <div className={cx(elementWrapperClassName, filter.wrapperClassName)}>
              <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
                {filter.label}
              </label>
              <FilterDatePicker
                endDate={getDateToDatePicker(selectedFilter[filter.key]?.[1])}
                onChange={(dateValue) =>
                  datesFilterChange({
                    dates: dateValue,
                    key: filter.key,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  })
                }
                placeholder={t('chooseDates')}
                startDate={getDateToDatePicker(selectedFilter[filter.key]?.[0])}
                testData={`filter-date-picker-${filter.key}`}
              />
            </div>
          )
        case 'boolean':
          return (
            <div className={cx(elementWrapperClassName, filter.wrapperClassName)}>
              <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
                {filter.label}
              </label>
              <Toggle
                className="p-4 align-bottom h-8"
                disabled={filter.disabled}
                handleChange={(val) => {
                  filterChange({
                    value: String(val),
                    key: filter.key,
                    isApplyOnBlur: false,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  })
                }}
                id={filter.key}
                key={filter.key}
                name={filter.key}
                testData={`filter-toggle-${filter.key}`}
                value={selectedFilter[filter.key] === 'true'}
              />
            </div>
          )
        default:
          return null
      }
    },
    [t, selectedFilter, setQueryParams, refs],
  )

  const removeAllFilters = useCallback(() => {
    setSelectedFilters({})
    removeQueryParam('filters')
  }, [removeQueryParam])

  return (
    <div className="mt-8">
      <Flex alignItems="center">
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
          {t('filters')}
        </Text>
        {!!Object.keys(selectedFilter).length && (
          <Text className="ml-4 cursor-pointer" color="text-black-500" onClick={removeAllFilters}>
            {t('clearAll')}
          </Text>
        )}
      </Flex>
      <Flex className="w-full mt-6 gap-4" wrap>
        {filtersList.map((filter) => getFilterInput(filter))}
      </Flex>
    </div>
  )
}

ReportsFilters.propTypes = {
  refs: PT.object,
  columns: PT.arrayOf(
    PT.shape({
      field: PT.string,
    }),
  ),
  availableFilters: PT.arrayOf(
    PT.shape({
      key: PT.string,
      type: PT.string,
    }),
  ),
}

ReportsFilters.defaultProps = {
  refs: [],
  columns: [],
  availableFilters: null,
}

export default ReportsFilters
