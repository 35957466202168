import { camelCase } from 'lodash'
import { parseDisplayFilterQuery } from '../../utils/utils'

export const validateFilters = (filters) =>
  Object.keys(filters).reduce((acc, filterKey) => {
    const validFilterValues = filters[filterKey].filter((value) => {
      if (!value && (value !== 0 || value !== '')) {
        return false
      }

      return true
    })

    if (!validFilterValues.length) {
      return acc
    }

    if (filters[filterKey].length === validFilterValues.length) {
      return {
        ...acc,
        [filterKey]: filters[filterKey],
      }
    }

    return {
      ...acc,
      [filterKey]: validFilterValues,
    }
  }, {})

export const applyStatusFilter = (
  filterKey,
  targetStatus,
  availableFilters,
  navigate,
  redirectUrl,
) => {
  if (!availableFilters || !filterKey || !navigate || !redirectUrl || !targetStatus) {
    return
  }

  const filter = availableFilters.find((currentFilter) => currentFilter.key === filterKey)

  if (!filter?.options?.length) {
    return
  }

  const selectedStatus = filter.options.find((status) =>
    status.title.toLowerCase().includes(targetStatus),
  )

  if (!selectedStatus) {
    return
  }

  navigate(`${redirectUrl}?filters=${camelCase(filterKey)}.${selectedStatus.key}`)
}

export const validateStoredFilters = (storedFilters, availableFilters) => {
  const filterMap = new Map(availableFilters.map((f) => [f.key, f]))

  return parseDisplayFilterQuery(storedFilters).every((userFilter) => {
    const { key, values } = userFilter
    const filter = filterMap.get(key)

    if (!filter) return false

    if (filter.options.length > 0) {
      const validOptions = new Set(filter.options.map((option) => option.key))
      return values.every((value) => validOptions.has(value))
    }
    return true
  })
}
