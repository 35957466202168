import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { contractDataShape } from '../../types/invoiceShapes'
import LienFinderPage from './finder/LienFinderPage'
import LienAlertPage from './alert/LienAlertPage'

const Liens = () => {
  return (
    <Routes>
      <Route element={<Navigate to="finder" replace />} path="/" />
      <Route element={<LienFinderPage />} path="/finder/*" />
      <Route element={<LienAlertPage />} path="/alert/*" />
    </Routes>
  )
}

Liens.propTypes = {
  contractData: contractDataShape,
}

export default Liens
