import React, { useCallback, useEffect, useMemo } from 'react'
import sizes from '../../ui-kit/sizes'
import { Button, Flex } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import PT from 'prop-types'
import { validateStoredFilters } from '../filters/filterUtils'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { VendorUserStoredViewQuery } from '../../queriesUpdated/queries/vendorUserStoredViewQuery.gql'
import { useQueryParams } from '../../hooks/useQueryParams'
import { UpdateVendorUserStoredViewMutation } from '../../queriesUpdated/mutations/updateVendorUserStoredViewMutation.gql'
import { DeleteVendorUserStoredViewMutation } from '../../queriesUpdated/mutations/deleteVendorUserStoredViewMutation.gql'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { useNotifications } from '../../hooks/useNotifications'
import align from '../../ui-kit/align'
import MenuDropdown from '../../ui-kit/components/menuDropdown/MenuDropdown'

const SavedTableViewControl = ({
  entity,
  contractId,
  availableFilters,
  setFilterValidationCompleted,
}) => {
  const { t } = useTranslation()
  const { queryParams, setQueryParams } = useQueryParams()
  const filtersQuery = queryParams.filters || null
  const sortParams = queryParams.sort
  const { newNotification } = useNotifications()

  const { data } = useCustomQuery({
    query: VendorUserStoredViewQuery,
    queryOptions: {
      variables: {
        contractId: contractId ? contractId : null,
        entity: entity,
      },
      skip: !entity,
    },
    rollbarOptions: {
      operationName: 'InvoicesOutstandingIndexQuery',
      target: 'InvoicesOutstanding',
    },
  })
  const vendorUserStoredViewParams = data?.vendorUserStoredView?.params
  const { filtersQuery: storedFilterParams, sortParams: storedSortParams } =
    vendorUserStoredViewParams ? vendorUserStoredViewParams : {}

  const isStoredFiltersValid = useMemo(() => {
    if (!data || !availableFilters) {
      return false
    }
    if (!storedFilterParams) {
      if (storedSortParams) {
        setQueryParams({ sort: storedSortParams })
      }
      return true
    }
    const isValidFilter = validateStoredFilters(storedFilterParams, availableFilters)
    if (isValidFilter && !filtersQuery && (storedFilterParams || storedSortParams)) {
      const queryParams = {}
      if (storedFilterParams) {
        queryParams.filters = storedFilterParams
      }
      if (storedSortParams) {
        queryParams.sort = storedSortParams
      }
      setQueryParams(queryParams)
    }
    return isValidFilter
  }, [data, availableFilters])

  useEffect(() => {
    setFilterValidationCompleted(isStoredFiltersValid)
  }, [isStoredFiltersValid])

  const onCompleted = useCallback(() => {
    newNotification({ success: t('savedFiltersUpdated') })
  }, [])

  const [createOrUpdateVendorUserStoredView, { loading: isSavingFiltersInProgress }] =
    useCustomMutation({
      mutation: UpdateVendorUserStoredViewMutation,
      onCompleted,
      mutationOptions: {
        refetchQueries: [VendorUserStoredViewQuery],
      },
      rollbarOptions: { operationName: 'UpdateVendorUserFilterMutation', target: 'Filters' },
    })

  const [deleteVendorUserStoredFilter, { loading: isDeletingInProgress }] = useCustomMutation({
    mutation: DeleteVendorUserStoredViewMutation,
    onCompleted,
    mutationOptions: {
      refetchQueries: [VendorUserStoredViewQuery],
    },
    rollbarOptions: { operationName: 'DeleteVendorUserStoredFilterMutation', target: 'Filters' },
  })

  const handleSavingFilters = () => {
    if (isSavingFiltersInProgress) {
      return
    }
    const variables = {
      data: {
        contractId: contractId ? contractId : null,
        entity,
        params: { filtersQuery, sortParams },
      },
    }
    createOrUpdateVendorUserStoredView({ variables })
  }

  const handleClearSavedFilters = () => {
    if (isDeletingInProgress) {
      return
    }
    const variables = {
      data: {
        contractId: contractId ? contractId : null,
        entity,
      },
    }
    deleteVendorUserStoredFilter({ variables })
  }

  return (
    <Flex alignItems="center" className="mr-2">
      <MenuDropdown
        className="relative"
        options={[
          {
            key: 'saveView',
            label: t('saveView'),
            onClick: () => handleSavingFilters(),
          },
          {
            key: 'deleteView',
            label: t('deleteView'),
            onClick: () => handleClearSavedFilters(),
            validationError: !vendorUserStoredViewParams,
          },
        ]}>
        <Button
          className="px-5"
          iconAlign={align.RIGHT}
          iconName="chevronDown"
          label={t('savedView')}
          size={sizes.SM}
          testData="selection-more"
          variant={buttonsVariants.TERTIARY_TINYGREY}
          fullWidth
        />
      </MenuDropdown>
    </Flex>
  )
}

SavedTableViewControl.propTypes = {
  entity: PropTypes.string,
  contractId: PropTypes.string,
  refetchQuery: PropTypes.func,
  setFilterValidationCompleted: PropTypes.func,
  availableFilters: PT.arrayOf(
    PT.shape({
      key: PT.string,
      type: PT.string,
    }),
  ),
}

SavedTableViewControl.defaultProps = {}

export default SavedTableViewControl
