import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import { Box, Tabs } from '../../../ui-kit'
import SearchByParty from './SearchByParty'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { getActiveTab } from '../../../utils/utils'
import SearchByProject from './SearchByProject'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { isCounterUser } from '../../settings/teamManagement/membershipRoles'

const LienFinder = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const currentUser = useCurrentUser()
  const { setBreadcrumbs } = useBreadcrumbs()
  const [activeTab, setActiveTab] = useState('')

  const handleTabClick = (tab) => {
    navigate(`./${tab}`)
    setActiveTab(tab)
  }
  const tabs = ['searchByParty', 'searchByProject']
  const tabsMap = tabs.map((tab) => ({
    label: t(tab),
    onClick: () => handleTabClick(tab),
    active: activeTab === tab,
  }))

  useEffect(() => {
    setBreadcrumbs([{ label: t('liens'), link: '/liens' }, { label: t('lienFinder') }])
  }, [])

  useEffect(() => {
    setActiveTab(getActiveTab(location, 'finder') || 'searchByParty')
  }, [location])

  useEffect(() => {
    if (
      isCounterUser(currentUser) ||
      (currentUser?.featureFlags && !currentUser.featureFlags.lienMonitor?.enabledForActor)
    ) {
      navigate('/dashboard')
    }
  }, [currentUser])

  return (
    <Box className="flex-1">
      <Tabs tabs={tabsMap} />
      <Routes>
        <Route element={<Navigate to="searchByParty" replace />} path="/" />
        <Route element={<SearchByParty />} path="/searchByParty" />
        <Route element={<SearchByProject />} path="/searchByProject" />
      </Routes>
    </Box>
  )
}

export default LienFinder
