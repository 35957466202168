import React, { useCallback, useMemo } from 'react'
import PT from 'prop-types'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, Flex, Text } from '../../../../ui-kit'
import { useBeforeUnload } from '../../../../hooks/useBeforeUnload'
import AlertModal from '../../../../ui-kit/components/alertModal/AlertModal'
import fontWeight from '../../../../ui-kit/fontWeight'
import Toggle from '../../../../ui-kit/components/inputs/Toggle'
import { useDirtyFormAlert } from '../../../../hooks/useDirtyFormAlert'
import Input from '../../../../ui-kit/components/inputs/Input'
import NDropdown from '../../../../ui-kit/components/dropdown/NDropdown'
import { getStatesList } from './StatesList'
import sizes from '../../../../ui-kit/sizes'
// eslint-disable-next-line max-len

const EditCustomerAlertsModal = ({
  onSubmit,
  isOpenedModal,
  isUpdateLoading = false,
  setIsOpenedModal,
  selectedCustomer,
  selectedCustomers = [],
  isSelectAllSelected = false,
}) => {
  const { t } = useTranslation()
  const initialValues = useMemo(
    () =>
      isSelectAllSelected || selectedCustomers.length > 1
        ? {
            enabled: false,
            city: '',
            state: '',
            address: '',
          }
        : {
            enabled: (selectedCustomers[0] || selectedCustomer)?.enabled || false,
            city: (selectedCustomers[0] || selectedCustomer)?.city || '',
            state: (selectedCustomers[0] || selectedCustomer)?.state || '',
            address: (selectedCustomers[0] || selectedCustomer)?.address || '',
          },
    [selectedCustomers, selectedCustomer, isSelectAllSelected],
  )

  const closeForm = useCallback(() => {
    setIsOpenedModal(false)
  }, [setIsOpenedModal])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      contentClass="w-200"
      isOpened={isOpenedModal}
      setIsOpened={requestClose}
      title={
        isSelectAllSelected || selectedCustomers.length > 1
          ? t('editAlerts')
          : t('editAlertModalTitle', {
              customerName: (selectedCustomers[0] || selectedCustomer)?.contract?.buyer?.name,
            })
      }
      titleClass="text-2xl font-semibold pl-3">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirty, touched }) => {
          useBeforeUnload({ when: touched && dirty })
          if (touched && dirty !== isFormDirty) {
            setDirtyFormState(dirty)
          }
          return (
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <Field name="enabled">
                {({ input }) => {
                  return (
                    <Flex className="w-full py-5 px-6 gap-5">
                      <Flex className="w-3/5" column>
                        <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                          {t('enableLienAlerts')}
                        </Text>
                        <Text color="text-black-500">{t('enableLienAlertsDescription')}</Text>
                      </Flex>
                      <Toggle handleChange={input.onChange} value={input.value} />
                    </Flex>
                  )
                }}
              </Field>
              {selectedCustomers.length <= 1 && (
                <>
                  <Text className="px-6" size={sizes.LG}>
                    {t('searchCriteria')}
                  </Text>
                  <Field name="address">
                    {({ input }) => {
                      return (
                        <Flex className="w-full py-5 px-6 gap-5">
                          <Flex className="w-3/5" column>
                            <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                              {t('addressLienAlerts')}
                            </Text>
                            <Text color="text-black-500">{t('addressLienAlertsDescription')}</Text>
                          </Flex>
                          <Input
                            className="w-80"
                            placeholder={t('addressOptionalPlaceholder')}
                            disabled
                            {...input}
                          />
                        </Flex>
                      )
                    }}
                  </Field>
                  <Field name="city">
                    {({ input }) => {
                      return (
                        <Flex className="w-full py-5 px-6 gap-5">
                          <Flex className="w-3/5" column>
                            <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                              {t('cityLienAlerts')}
                            </Text>
                            <Text color="text-black-500">{t('cityLienAlertsDescription')}</Text>
                          </Flex>
                          <Input
                            className="w-80"
                            placeholder={t('cityOptionalPlaceholder')}
                            disabled
                            {...input}
                          />
                        </Flex>
                      )
                    }}
                  </Field>
                  <Field name="state">
                    {({ input, meta }) => {
                      return (
                        <Flex className="w-full py-5 px-6 gap-5">
                          <Flex className="w-3/5" column>
                            <Text color="text-black-500" fontWeight={fontWeight.SEMIBOLD}>
                              {t('stateLienAlerts')}
                            </Text>
                            <Text color="text-black-500">{t('stateLienAlertsDescription')}</Text>
                          </Flex>
                          <div className="w-80">
                            <NDropdown
                              className="w-3/5"
                              errorMessage={
                                meta.error && meta.submitFailed ? meta.error : undefined
                              }
                              listClass="max-h-40 p-1 pt-0 pb-0"
                              onChange={input.onChange}
                              options={getStatesList(t)}
                              placeholder={t('stateLienAlertsPlaceholder')}
                              value={input.value}
                              isDisabled
                            />
                          </div>
                        </Flex>
                      )
                    }}
                  </Field>
                </>
              )}
              <Flex className="w-full mt-6" justifyContent="end">
                <Button
                  className="mr-2"
                  disabled={isUpdateLoading}
                  label={t('cancel')}
                  onClick={requestClose}
                  variant={'tertiary'}
                />
                <Button
                  disabled={
                    (isSelectAllSelected || selectedCustomers.length > 1 ? false : !dirty) ||
                    isUpdateLoading
                  }
                  label={t('save')}
                  type={'submit'}
                />
              </Flex>
              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
      />
    </Dialog>
  )
}

export default EditCustomerAlertsModal

EditCustomerAlertsModal.propTypes = {
  onSubmit: PT.func.isRequired,
  isOpenedModal: PT.bool,
  isUpdateLoading: PT.bool,
  setIsOpenedModal: PT.func.isRequired,
  selectedCustomer: PT.object,
  selectedCustomers: PT.arrayOf(PT.object),
  isSelectAllSelected: PT.bool,
}
