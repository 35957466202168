export default {
  WHITE: 'white',
  RED: 'red',
  PRIMARY: 'primary',
  BLACK: 'black',
  GREY: 'grey',
  LIGHTGREY: 'lightgrey',
  PURPLE: 'purple',
  GREEN: 'green',
  BLUE: 'blue',
  ORANGE: 'orange',
  TINYGREY: '#F9FAFB',
  LIGHT_GRAY1: '#9CA3AF',
  GRAY_700: '#374151',
  GRAY_500: '#6B7280',
  PRIMARY_200: '#FEF2EF',
  SUPPLI_PURPLE: '#818cf8',
  SUPPLI_BLUE_03: '#56CCF2',
  SUPPLI_ORANGE: '#F85737',
  CHART_BANDS: '#F9FAFB',
  GREEN_600: '#27AE60',
  BLACK_400: '#d1d5db',
  GREEN_500: '#059669',
  CLEARENT_ICON_COLOR: '#7f8284',
  YELLOW_500: '#F59E0B',
  DARK_RED: 'text-darkRed-600',
}
