import PT from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Input } from '../../ui-kit'
import { validateEmail, validatePhoneNumber, validateRequiredField } from '../../utils/validators'
import { useTranslation } from 'react-i18next'
import NDropdown from '../../ui-kit/components/dropdown/NDropdown'
import { UpdateVendorUserMutation } from '../../queries/mutations/usersRequest.gql'
import { useNotifications } from '../../hooks/useNotifications'
import { normalizePhone } from '../../utils'
import { useBeforeUnload } from '../../hooks/useBeforeUnload'
import AlertModal from '../../ui-kit/components/alertModal/AlertModal'
import { membershipRoles } from '../../pages/settings/teamManagement/membershipRoles'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { VendorSettingsQuery } from '../../queries/vendors.gql'

const EditUserForm = ({
  setIsOpenedModal,
  user,
  isFormDirty,
  setDirtyFormState,
  closeForm,
  requestClose,
  enabledFields,
}) => {
  const { t } = useTranslation()
  const initialValues = {
    ...user,
    phoneNumber: user.formattedPhoneNumber,
    officePhoneNumber: user.formattedOfficePhoneNumber,
  }

  const onCompleted = useCallback(() => {
    setIsOpenedModal(false)
  }, [])
  const [updateUser, { loading }] = useCustomMutation({
    mutation: UpdateVendorUserMutation,
    onCompleted,
    rollbarOptions: { operationName: 'UpdateVendorUserMutation', target: 'EditUserForm' },
    mutationOptions: {
      refetchQueries: [VendorSettingsQuery],
    },
  })
  const { newNotification } = useNotifications()

  const handleSubmitForm = (values) => {
    const data = {
      email: values.email,
      lastName: values.lastName,
      firstName: values.firstName,
      companyRole: values.companyRole,
      phoneNumber: values.phoneNumber,
      membershipRole: values.membershipRole,
      officePhoneNumber: values.officePhoneNumber,
    }

    const variables = {
      id: user.id,
      data: data,
    }
    updateUser({ variables }).then(({ data }) => {
      const responseData = data?.updateVendorUser || {}

      if (responseData?.entity) {
        newNotification({ success: t('userUpdatedSuccessfully') })
      }
    })
  }

  const membershipRolesOptions = useMemo(
    () =>
      Object.values(membershipRoles).map((membershipRole) => ({
        label: t(membershipRole),
        value: membershipRole,
        testData: membershipRole,
      })),
    [t],
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, dirty }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col mt-6 py-0 px-2" onSubmit={handleSubmit}>
            <div className="w-full mr-4 flex flex-row">
              <div className="w-6/12">
                <Field name="firstName">
                  {({ input, meta }) => {
                    return (
                      <Input
                        disabled={enabledFields && !enabledFields.includes('firstName')}
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('firstName')}
                        placeholder={t('firstName')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="ml-4 w-6/12">
                <Field name="lastName">
                  {({ input, meta }) => {
                    return (
                      <Input
                        disabled={enabledFields && !enabledFields.includes('lastName')}
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('lastName')}
                        placeholder={t('lastName')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>
            </div>

            <div className="w-full mt-6">
              <Field name="phoneNumber" parse={normalizePhone}>
                {({ input, meta }) => {
                  return (
                    <Input
                      disabled={enabledFields && !enabledFields.includes('phoneNumber')}
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('mobilePhone')}
                      placeholder={t('phone')}
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="officePhoneNumber" parse={normalizePhone}>
                {({ input, meta }) => {
                  return (
                    <Input
                      disabled={enabledFields && !enabledFields.includes('officePhoneNumber')}
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('officePhone')}
                      placeholder={t('phone')}
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="email">
                {({ input, meta }) => {
                  return (
                    <Input
                      disabled="true"
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('email')}
                      placeholder={t('email')}
                      type="email"
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="membershipRole">
                {({ input, meta }) => {
                  return (
                    <NDropdown
                      errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                      id={input.name}
                      inputClassName="w-full"
                      isDisabled={enabledFields && !enabledFields.includes('membershipRole')}
                      label={t('role')}
                      listClass="max-h-44 p-1 pt-0 pb-0"
                      name={input.name}
                      onChange={(e) => {
                        input.onChange(e.target.value)
                      }}
                      options={membershipRolesOptions}
                      placeholder={t('role')}
                      testData="membership-roles"
                      value={input.value}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6 flex flex-row justify-end">
              <Button
                className="mr-2"
                disabled={loading}
                label={t('cancel')}
                onClick={requestClose}
                testData="cancel-edit"
                variant="tertiary"
              />
              <Button label={t('save')} testData="save-updates" type="submit" />
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => ({
        firstName: validateRequiredField(values.firstName),
        lastName: validateRequiredField(values.lastName),
        email: validateRequiredField(values.email) || validateEmail(values.email),
        phoneNumber: validatePhoneNumber(values.phoneNumber),
        officePhoneNumber: values.officePhoneNumber
          ? validatePhoneNumber(values.phoneNumber)
          : undefined,
        membershipRole: validateRequiredField(values.membershipRole),
      })}
    />
  )
}

EditUserForm.propTypes = {
  closeForm: PT.func.isRequired,
  isFormDirty: PT.bool.isRequired,
  requestClose: PT.func.isRequired,
  setIsOpenedModal: PT.func,
  setDirtyFormState: PT.func.isRequired,
  user: PT.shape({
    id: PT.string,
    email: PT.string,
    firstName: PT.string,
    lastName: PT.string,
    fullName: PT.string,
    role: PT.string,
    createdAt: PT.string,
    permissions: PT.string,
    formattedPhoneNumber: PT.string,
    formattedOfficePhoneNumber: PT.string,
    status: PT.string,
  }).isRequired,
  enabledFields: PT.arrayOf(PT.string),
}

export default EditUserForm
