import { DateTime } from 'luxon'

export const getDateRange = (action, config) => {
  const currentDate = DateTime.now().startOf('day')
  const targetDate =
    action === 'plus'
      ? currentDate.plus(config).startOf('day')
      : currentDate.minus(config).startOf('day')

  return action === 'plus'
    ? [currentDate.toJSDate(), targetDate.toJSDate()]
    : [targetDate.toJSDate(), currentDate.toJSDate()]
}
